import firebase from "firebase/compat/app";
export const SET_EXPLORE_USERS = "SET_EXPLORE_USERS";

export const setUsers = (discover) => ({
  type: SET_EXPLORE_USERS,
  discover,
});

export const startSetExploreUsers = () => {
  return (dispatch) => {
    firebase
      .database()
      .ref("/discover")
      .once("value")
      .then((snapshot) => {
        const discover = snapshot.val();
        dispatch(setUsers(discover));
      });
  };
};
