export const TIMELINE = '/';
export const EXPLORE = '/explore/user';
export const ACTIVITY = '/activity/users';
export const SIGN_UP = '/';
export const SIGN_IN = '/signin/user';
export const PASSWORD_FORGET = '/forgot-password/user';
export const ACCOUNT = '/account/user';
export const PROFILE = '/:uid';
export const PROFILE_FEATURED = '/:uid/featured'
export const LIST_DETAIL = '/:uid/:slug';
export const LIST_EDIT = '/edit/:slug';
export const FOLLOWING_FRIENDS = '/:uid/following';
export const FOLLOWERS_FRIENDS = '/:uid/followers';
export const TERMS_PRIVACY = '/:uid/legal/privacy/';
export const ABOUT = '/terms/legal/privacy/';
export const APP = '/terms/legal/privacy/';