import React, { Component } from 'react';
import * as routes from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import { FormGroup, FormControl, Button } from "react-bootstrap";
import logo from "../../assets/images/logo@2x.png";
import PropTypes from 'prop-types';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { auth, db } from '../../firebase';

const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/tierly-712dc.appspot.com/o/profile_images%2Fdefault_avatar.jpg?alt=media&token=2f96b498-bedf-410e-8558-08c3983ac0ee';
const tierlyListImage = 'https://firebasestorage.googleapis.com/v0/b/tierly-712dc.appspot.com/o/profile_images%2FKju2aU4JZUYhEUh5UfbpvCH4ivj1%2FKju2aU4JZUYhEUh5UfbpvCH4ivj1.jpg?alt=media&token=a261d646-159f-4edb-a577-1cb7d51d0e70';
// const defaultImage = '../../assets/images/default_avatar.jpg'

const propTypes = {
  clearState: PropTypes.func.isRequired,
  signUpUser: PropTypes.func.isRequired,
  authError: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

const SignUpPage = ({ history }) =>
  <div style={{padding:16, borderRadius: 80}}>
    <img src={logo} className="sign-in-logo" alt="logo" draggable="false" />
    <div style={{marginTop:8}}>
      <h4 className="login-text" style={{}}>Create an account in just a few seconds</h4>
    </div>
    <div style={{marginTop: 16}}>
      <SignUpForm history={history} />
    </div>
    <div style={{marginTop: 24}}>
      <p>By signing up, you agree to our <Link to={routes.TERMS_PRIVACY}>Terms, Data Policy and Cookies Policy.</Link></p>
    </div>
    <div style={{marginTop: 10}}>
      <p>Tierly was created and is maintainened by one person, if something breaks or doesnt work correctly please be patient with me, i'm still trying to get this right.</p>
    </div>
    <div style={{marginTop: 10}}>
      <p>Please be respectul and kind to each other ❤️</p>
    </div>
    </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  name: '',
  password: '',
  photoURL: '',
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...INITIAL_STATE,
      displayName: '',
      usernames: [],
      usernameError: '',
      emailError: '',
      passwordError: '',
      error: '',
      email: '',
    };
  }

  onSubmit = (event) => {
    const {
      email,
      name,
      displayName,
      password,
      photoURL
    } = this.state;

    const {
      history,
    } = this.props;

    let fireRef = firebase.firestore().collection('usernamesDB').doc(`${displayName}`).get();
    
    fireRef.then((docSnapshot) => {
      if (docSnapshot.exists || displayName == '') {
        this.setState({usernameError: 'Sorry username is taken =('})
      } else {
        
        auth.doCreateUserWithEmailAndPassword(email, password, displayName, email, photoURL)
        .then(() => {

          firebase.auth().currentUser.updateProfile({
            displayName,
            photoURL: defaultImage
          })
        
          const uid = firebase.auth().currentUser.uid

          const realTimeDBUserOBJ = {
            displayName: displayName.toLowerCase(),
            following: 0,
            followers: 0,
            posts: 0,
            uid,
            photoURL: defaultImage,
            slug: displayName,
            type: 'user',
            verified: false,
          };

          const firestoreDBUserOBJ = {
            admin: false,
            bio: '',
            displayName: displayName.toLowerCase(),
            email: email,
            posts: 0,
            following: 0,
            followers: 0,
            uid,
            phonenumber: '',
            photoURL: defaultImage,
            slug: displayName,
            city: '',
            package_level: 'basic',
            type: 'user',
            verified: false,
            token: '',
            tokens: {
              activity_notifications: false,
              promo_switch: false,
            },
          };

          firebase.database().ref(`users/${uid}`).set(realTimeDBUserOBJ).catch(err => {
            this.setState({emailError: err})
          });

          firebase.firestore().collection('usersDB').doc(`${uid}`).set(firestoreDBUserOBJ).catch(err => {
            this.setState({emailError: err})
          });

          let updates = {}
          updates[`usernames/${displayName}/`] = uid;
          
          firebase.database().ref().update(updates)

          const newUser = {
            uid,
            displayName,
            photoURL: defaultImage,
            verified: false,
          };

          firebase.database().ref(`user-search/${uid}`).set(newUser).catch(err => {
            this.setState({emailError: err})
          });;
  
          firebase.firestore().collection('usernamesDB').doc(`${displayName}`).set(newUser).catch(err => {
            this.setState({emailError: err})
          });

          this.setState(() => ({ ...INITIAL_STATE }));
          history.push(routes.TIMELINE);

        }).catch(error => { this.setState({ error: error.message });})

      }
    }).catch(error => { this.setState({ error: error.message });})

    event.preventDefault();
  }

  onEmailChange = (event) => {
    const email = event.target.value;
    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({emailError: 'Please provide valid email'})
    } else {
      this.setState({emailError: ''})
    }
    this.setState({email})
  }

  displayNameChecker = () => {

      const displayName = this.state.displayName

      let fireRef = firebase.firestore().collection('usernamesDB').doc(`${displayName}`).get();
      
      fireRef.then((docSnapshot) => {
        if (docSnapshot.exists || displayName == '') {
          this.setState({usernameError: 'Sorry username is taken =('})
        } else {
          this.setState({
            usernameError: 'Username is available!'
          })
        }
    }).catch(err => console.log(err))
}

  displayNameAvailable = () => {    
    this.setState({usernameError: ''})
  }

  onDisplayNameChange = (event) => {
    const displayName = event.target.value.toLowerCase().trim()
    displayName.replace(/\s/g, '')

   if (displayName && displayName.length < 3) {
      this.setState({usernameError: 'Minimum of 3 characters'})
    } else if (displayName && displayName.length > 22) {
      this.setState({usernameError: 'Maximum of 22 characters'})
    } else if (displayName && !/^[a-zA-Z0-9_]*$/i.test(displayName)) {
      this.setState({usernameError: 'Please provide username with no special characters or spaces!'})
    } else {
      this.setState({usernameError: ''})
      this.setState({displayName})
    }
    this.setState({displayName})
  }

  onPasswordChange = (event) => {
    const password = event.target.value;
    if (password && password.length <= 6) {
      this.setState({passwordError: 'Minimum 6 characters'})
    } else if (password && password.length > 6){
      this.setState({passwordError: ''})
    }
    this.setState({password})
  }

  render() {
    const { email, displayName, password, error, usernameError, emailError } = this.state;

    const isInvalid = email === '' || password === '' || usernameError !== '' || displayName === null || displayName == '';

    console.log(error, "ERROR")
    return (
      <FormGroup bsSize="large">
        <form onSubmit={this.onSubmit}>

          <FormGroup bsSize="large">
            <FormControl
              style={{backgroundColor: '#EEE'}}
              value={displayName}
              onChange={this.onDisplayNameChange}
              // onChangeText={(displayName) => this.setState({displayName: displayName.replace(/\s/g, '')})}
              onBlur={this.displayNameChecker}
              type="text"
              placeholder="Username"
            />
            {this.state.usernameError && <p style={{marginTop:4}}>{this.state.usernameError}</p>}
          </FormGroup>

          <FormGroup bsSize="large">
              <FormControl
                style={{backgroundColor: '#EEE'}}
                value={email}
                onChange={this.onEmailChange}
                onBlur={this.displayNameAvailable}
                type="text"
                placeholder="Email Address"
              />
              {error && <p>{error}</p>}
              {this.state.emailError && <p>{this.state.emailError}</p>}
              {error ? (<p>{error.message}</p>) : (<p></p>)}
              {this.state.error && <p>{this.state.emailError}</p>}
            </FormGroup>
          

          <FormGroup bsSize="large">
            <FormControl
              style={{backgroundColor: '#EEE'}}
              value={password}
              onChange={this.onPasswordChange}
              type="password"
              placeholder="Password"
              secure
            />
            {this.state.passwordError && <p>{this.state.passwordError}</p>}
          </FormGroup>

          <Button 
            bsStyle="primary" 
            bsSize="large" 
            className="btn-blank sign-up-tierly-btn" disabled={isInvalid} type="submit">
            Sign up
          </Button>
        {/* {this.state.error && <p>{this.state.error}</p>} */}
          
        </form>
      </FormGroup>
    );
  }
}

const SignUpLink = () =>
  <p>
    Don't have an account?
    {' '}
    <Link to={routes.SIGN_UP}>Request Access</Link>
  </p>

SignUpPage.propTypes = propTypes;

export default withRouter(SignUpPage);

export {
  SignUpForm,
  SignUpLink,
};