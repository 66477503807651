import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Col, Button } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import { startRemoveList } from "../../actions/lists";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  
  
  
  // change background colour if dragging
  background: isDragging ? "#EEEEEE" : "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#FFFFFF" : "#f5f5f7",
  // width: 600,
});


class ListEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: props.list.displayName ? props.list.displayName : '',
      photoURL: props.list.photoURL ? props.list.photoURL : "",
      uid: props.list.uid ? props.list.uid : "",
      id: props.list.id ? props.list.id : "",
      title: props.list ? props.list.title : "",
      slug: props.list ? props.list.slug : "",
      items: props.list.items ? props.list.items : [],
      img_url: props.list.img_url ? props.list.img_url : "",
      likes: props.list.likes ? props.list.likes : "",
      createdAt: moment(),
      liked: props.list.liked ? props.list.liked : [],
      link_title: '',
      link_url: '',
      error: ""
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  state = {
    title: '',
    items: [],
    createdAt: moment(),
    img_url: '',
    error: ""
  };

  onTitleChange = e => {
    const title = e.target.value;
    this.setState(() => ({ title }));
  };

  handleAddItem(link_title, link_url) {

    if (!link_title) {
      return 'Enter valid value to add item';
    } else if (this.state.items.indexOf(link_title) > -1) {
      return 'This item already exists';
    }

    let item = {
      link_title,
      link_url,
      id: _.toString(this.state.items.length + 1)
    }

    this.setState((prevState) => {
      return {
        items: prevState.items.concat(item)
      };
    })

  }

  removeItem(item) {
    const updatedItemCount = this.state.items.filter(items => {
      return items !== item
    })
    this.setState({
      items: [...updatedItemCount]
    })
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });

  }

  onSubmit = e => {
    e.preventDefault();
    if (!this.state.title) {
      this.setState(() => ({ error: "Please provide title" }));
    } else {
      this.setState(() => ({ error: "" }));

      this.props.onSubmit({
        displayName: this.state.displayName,
        title: this.state.title,
        photoURL: this.state.photoURL,
        img_url: this.state.img_url,
        likes: this.state.likes,
        uid: this.state.uid,
        slug: this.state.slug,
        id: this.state.id,
        items: this.state.items,
        createdAt: this.state.createdAt.valueOf(),
        liked: this.state.liked,
      });
    }
  };

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
}

  removeListButton() {
    return (
      
        <Button 
                onClick={() => {
                  let response = prompt("Are you sure? [type: yes to delete post]")
                  if(response != null){
                  if (response.toLowerCase() === 'yes') {
                    this.props.startRemoveList({ id: this.props.list.id });
                    this.props.history.push("/");
                  }
                }
                  
                
              }}>
          <div className="remove-tierly-div-style">
            <i className="fa fa-trash" aria-hidden="true" /> Remove
          </div>
        </Button>
      
    );
}

  render() {
    const { items } = this.props.list;
    const itemsArray = _.toArray(items);
    const { input } = this.props;
    const { img_url } = this.state;

    const post_image = img_url ? (
      <div className="account-img-wrapper">
        <img src={img_url} className="account-user-img" alt="user avatar" draggable={false} />
      </div>
    ) : (
      <div style={{ display: 'flex', backgroundColor: '#FFF', borderRadius: 12 }}>
        <div style={{ width: 50, height: 50, paddingTop: 12, paddingLeft: 12 }}>
          <div>
          <label htmlFor="files">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div>
                <i className="fas fa-image" style={{fontSize: 24, color: '#adadaf'}}/>
              </div>
            </div>
          </label>
          <input id="files" onChange={event => { this.displayPicture(event) }} {...input} style={{visibility:'hidden'}} type="file"/>
          </div>
        </div>
      </div>
    )

    return (
      <div>
        {this.state.error && <p>{this.state.error}</p>}
        <form onSubmit={this.onSubmit}>

          <div className="list-card-style">
            <div className="list-card">
              <div style={{ display: "flex", flex: 1, flexDirection: "row", padding: 16 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>{post_image}</div>
                </div>

                <div style={{ marginLeft: 16,  flex: 1 }}>
                  <input
                    disabled
                    style={{
                      height: 45,
                      width: "100%",
                      borderRadius: 12,
                      paddingLeft: 8,
                      backgroundColor: '#FFF'
                    }}
                    type="text"
                    placeholder="List title"
                    autoFocus
                    value={this.state.title}
                    onChange={this.onTitleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <AddItemForm items={this.props.items} onSubmit={this.handleAddItem}/>
          <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className="item-card"
                    >
                          {item.link_url ? (
                            <div className="list-profile-link-wrapper">
                              <div className="item-link-container">
                                <div className="item-link">
                                  <i
                                    className="fa fa-link item-link-icon-style"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}

                          <div className="item-detail-wrapper" index={index}>
                            <div>
                              <p className="item-content">{item.link_title}</p>
                              <p className="item-link-url item-link-wrapper">
                                {item.link_url}
                              </p>
                            </div>
                          </div>
                          <div className="item-link-container">
                            <div className="delete-link-icon">
                              <div
                                index={index}
                                onClick={(e) => this.removeItem(item)}
                              >
                                <i className="fas fa-trash-alt delete-icon"></i>
                              </div>
                            </div>
                          </div>

                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      
    </form>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
          <div style={{flex: 1}}>
            <Button
              className="update-btn-container"
              onClick={this.onSubmit}
            >
              Update
            </Button>
          </div>
          <div style={{justifyContent: 'flex-end', alignItems: 'center'}}>
            {this.removeListButton()}
          </div>
        </div>
      </div>
    );
  }
}

class AddItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items: this.props.items ? this.props.items : [],
      link_title: props.item ? props.item.link_title : '',
      link: props.item ? props.item.link : '',
      error: undefined
    };
  }

  state = {
    link_title: '',
    link_url: '',
    error: ''
  }

  onContentChange = (e) => {
    const link_title = e.target.value;
    this.setState(() => ({ link_title }));
  }
  
  onLinkChange = (e) => {
    const link_url = e.target.value;
    this.setState(() => ({ link_url }));
  }

  onSubmit = (e) => {
    e.preventDefault();
    
    if (!this.state.link_title) {
      this.setState(() => ({ error: 'Please provide item' }))
    } else {
      this.setState(() => ({ error: '', link_title: '', link_url: '' }))
      this.props.onSubmit(this.state.link_title, this.state.link_url)
    }
  }

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
}
  
  render() {
    return (
      <div className="list-card-style">
        {this.state.error && <p>{this.state.error}</p>}
        <div autoComplete="off" onKeyPress={this.onKeyPress} onSubmit={this.onSubmit}>
        
        
          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
            <div style={{marginLeft: 16}}>
              <i className="fas fa-pencil-alt"/>
            </div>
            <div className="add-item-input">
              <input 
                type="text"
                placeholder="Link title"
                value={this.state.link_title}
                onChange={this.onContentChange}
                style={{width: '100%'}}
                autoComplete="off" />
            </div>
          </div>
          

          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
            <div style={{marginLeft: 16}}>
              <i className="fa fa-link" aria-hidden="true"/>
            </div>
            <div className="add-item-input">
              <input
                type="text"
                placeholder="Past link here (Where does this link to?)"
                value={this.state.link_url}
                onChange={this.onLinkChange}
                style={{width: '100%'}}
                autoComplete="off"
              />
            </div>
          </div>
  
          <div onClick={this.onSubmit}  className="add-item-btn-container">
            <div className="add-item-btn">
              <strong>+ Add link</strong>
            </div>
          </div>
            
          </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  userProps: state.userProps
});

const mapDispatchToProps = dispatch => ({
  startRemoveList: data => dispatch(startRemoveList(data))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ListEditForm);