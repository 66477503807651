// Commentts Reducer
import { ADD_COMMENT, SET_COMMENTS } from '../actions/comments'


const commentsReducerDefaultState = [];

export default (state = commentsReducerDefaultState, action) => {
  switch (action.type) {
    
    case ADD_COMMENT:
      return [
        ...state,
        action.commentBody
      ];
      
      case SET_COMMENTS:
      return action.payload;
      
    default:
      return state;
  }
};
