import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import * as routes from '../../constants/routes';

import ScrollToTop        from '../utilities/ScrollToTop'
import SignInPage from '../signIn';
import PasswordForgetPage from '../passwordForget';
import Timeline from '../timeline';
import AccountPage from '../account';
import withAuthentication from '../session/withAuthentication';
import Activity from '../activity'
import ExploreList from '../explore/ExploreList'
import ListDetail from '../timeline/ListDetail';
import ListEditView from '../lists/EditListView'
import PublicProfile from '../publicProfile'
import PublicProfileFeatured from '../publicProfile/PublicProfileFeatured'
import FollowingFriendsList from '../friends/FollowingFriendsList';
import FollowersFriendsList from '../friends/FollowersFriendsList';
import TermsAndPrivacy from "../utilities/TermsAndPrivacy"
import About from "../utilities/About"
import MobileApp from "../utilities/MobileApp"

export const history = createHistory();
const App = () =>
  <Router>
    <ScrollToTop>
      <Switch>
        <div>
          <Route exact path={routes.TIMELINE}               component={Timeline} />
          <Route exact path={routes.SIGN_IN}                component={SignInPage} />
          <Route exact path={routes.LIST_DETAIL}            component={ListDetail} />
          <Route exact path={routes.LIST_EDIT}              component={ListEditView} />
          <Route exact path={routes.ACCOUNT}                component={AccountPage} />
          <Route exact path={routes.PROFILE}                component={PublicProfile} />
          <Route exact path={routes.PROFILE_FEATURED}       component={PublicProfileFeatured} />
          <Route exact path={routes.EXPLORE}                component={ExploreList} />
          <Route exact path={routes.ACTIVITY}               component={Activity} />
          <Route exact path={routes.FOLLOWING_FRIENDS}      component={FollowingFriendsList} />
          <Route exact path={routes.FOLLOWERS_FRIENDS}      component={FollowersFriendsList} />
          <Route exact path={routes.PASSWORD_FORGET}        component={PasswordForgetPage} />
          <Route exact path={routes.TERMS_PRIVACY}          component={TermsAndPrivacy} />
          <Route exact path={routes.ABOUT}                  component={About} />
          <Route exact path={routes.APP}                    component={MobileApp} />
        </div>
      </Switch>
    </ScrollToTop>
  </Router>

export default withAuthentication(App);


