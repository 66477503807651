// Lists Reducer
import { FETCH_FEATURED_FRIENDS } from '../actions/featured_friends'


const featuredFriendsReducerDefaultState = [];

export default (state = featuredFriendsReducerDefaultState, action) => {
  switch (action.type) {    
    case FETCH_FEATURED_FRIENDS:
      return action.payload;  
    default:
      return state;
  }
};
