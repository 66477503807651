import {createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import session from '../reducers/session';
import user from '../reducers/user';
import authReducer from '../reducers/auth';
import userProps from '../reducers/userProps';
import lists from '../reducers/lists';
import likes from '../reducers/likes';
import comments from '../reducers/comments';
import featured_friends from '../reducers/featured_friends';
import following from '../reducers/following';
import followers from '../reducers/followers';
import explore from '../reducers/explore';
import discover from '../reducers/discover';
import filters from '../reducers/filters';
import activity from '../reducers/activity';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
    combineReducers({
        sessionState: session,
        userState: user,
        auth: authReducer,
        userProps,
        lists,
        likes,
        discover,
        comments,
        featured_friends,
        following,
        followers,
        explore,
        filters,
        activity
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  
  return store;
  
}