import React from "react";
import "../../styles/App.css";

const ListItem = ({ link_title, link_url }) => (
  <div className="no-margin">
  
  
  {link_url ? (
    <a href={`${link_url}`} target="_blank" rel="noopener noreferrer">
    <div className="item-card">

      {link_url ? (
        <div className="item-link-container">
          <div className="item-link">
            <i className="fa fa-link item-link-icon-style" aria-hidden="true"/>
          </div>
        </div>
      ) : (
        <div />
      )}

    <div className="item-detail-wrapper">
      <div>
        <p className="item-content">{link_title}</p>
        <p className="item-link-url item-link-wrapper">{link_url}</p>
      </div>
    </div>

    </div>
  </a>
  ) : (
    <div className="item-card">
    
    <div className="item-detail-wrapper" style={{paddingTop: 8, paddingBottom: 8}}>
      <div>
        <p className="item-content">{link_title}</p>
        <p className="item-link-url item-link-wrapper">{link_url}</p>
      </div>
    </div>

  </div>
  )}
    
  </div>
);

export default ListItem;
