import React                    from 'react';
import ReactDOM                 from 'react-dom';
import { Provider }             from 'react-redux';
import configureStore           from "./store/configureStore";
import { login }                from './actions/auth';
import { startUserProps }       from './actions/userProps';
import { fetchTimelineLists }   from "./actions/lists";
import { startSetExploreLists } from './actions/explore';
import { startSetExploreUsers } from './actions/discover';
import { startSetLikes }        from './actions/likes';
import * as serviceWorker       from './serviceWorker';
import TierlyAppSplash          from './TierlyAppSplash'
import App                      from './components/app';
import './styles/index.css';
import firebase                 from 'firebase/compat/app';

const store = configureStore();

const TierlyApp = (
      <Provider store={store}>
        <App />
      </Provider>
    )

let hasRendered = false;

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(TierlyApp, document.getElementById('root'));
    hasRendered = true;
  }
};

ReactDOM.render(<TierlyAppSplash/>, document.getElementById('root'));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(login(user));
    store.dispatch(startSetExploreLists())
    store.dispatch(startSetExploreUsers())
    store.dispatch(startUserProps(user))
    store.dispatch(startSetLikes())
    store.dispatch(fetchTimelineLists(user)).then(() => {
      renderApp();
    });
  } else {
    // store.dispatch(startSetExploreLists())
    renderApp();
    // history.push('/signin/user')
  }
});

serviceWorker.unregister();
