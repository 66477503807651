import { FETCH_USER_LIKES, FETCH_PUBLIC_PROFILE_LIKES } from '../actions/likes'
const defaultLikesState = {};

export default (state = defaultLikesState, action) => {
  switch (action.type) {
    case FETCH_USER_LIKES:
      return action.likes;
    case FETCH_PUBLIC_PROFILE_LIKES:
      return action.likes;
    default:
      return state;
  }
};
