import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import _ from "lodash";
import FollowButtonFake from "../followButton/FollowButtonFake";
import { Modal, Col, Button } from "react-bootstrap";
import "../../styles/App.css";
import firebase from "firebase/compat/app";
class UserCard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      user: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleHide() {
    this.setState({ show: false });
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: true });
      }
    });
  }
  render() {
    const {
      photoURL,
      uid,
      displayName,
      bio,
      posts,
      followers,
      verified,
    } = this.props;

    return (
      <a href={`/${displayName}`}>
        <div
          className="profile-user-card-wrapper"
          style={{
            display: "flex",
            flex: 1,
            marginBottom: 20,
            flexDirection: "column",
          }}
        >
          <div className="sidebar-img-wrapper">
            <img
              src={photoURL}
              className="sidebar-user-img"
              alt="user avatar"
              draggable={false}
            />
          </div>

          <div
            className="sidebar-card"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div
              className="user-card-details-wrapper"
              style={{ textAlign: "center" }}
            >
              <a href={`/${displayName}`}>
                <strong>
                  <p className="sidebar-user-name">
                    {displayName}{" "}
                    {verified ? <span className="verified" /> : <span />}{" "}
                  </p>
                </strong>
              </a>
              <p className="sidebar-user-bio">{bio}</p>
            </div>
          </div>
          <FollowButtonFake />
        </div>
      </a>
    );
  }
}

const mapStateToProps = (state) => ({
  userProps: state.userProps,
});

export default connect(mapStateToProps, undefined)(UserCard);
