import firebase from 'firebase/compat/app';

export const SET_EXPLORE_LISTS = 'SET_EXPLORE_LISTS';

export const setLists = (exploreLists) => ({
    type: SET_EXPLORE_LISTS,
    exploreLists
  });
  
  export const startSetExploreLists = () => {
    return (dispatch) => {
      return firebase.firestore()
      .collection('postsDB')
      .orderBy('createdAt', 'desc')
      .limit(5)
      .get()
      .then(docSnapshot => {
        const exploreLists = [];
        docSnapshot.forEach(doc => {
          exploreLists.push(doc.data());
      });
        dispatch(setLists(exploreLists));
      });
    };
  };
