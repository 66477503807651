import database  from '../firebase/firebase'
import firebase from 'firebase/compat/app';

export const SET_LIKES = 'SET_LIKES';

// // SET LISTS FOR EXPLORE SECTION
export const setLikes = (likes) => ({
    type: SET_LIKES,
    likes
  });
  
  export const startSetLikesActivity = () => {
      
    // const {currentUser } = firebase.auth().currentUser;

    return (dispatch, getState) => {
        //const uid = getState().auth.uid;
        firebase.auth().onAuthStateChanged(user => { 
      return database.ref(`/activity/${user.uid}/likes`).once('value').then((snapshot) => {
        const likes = [];
        
        snapshot.forEach((childSnapshot) => {
          likes.push({
            id: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        
        dispatch(setLikes(likes));
        // console.log("------------- ACTIVITY LISTS ------------")
        // console.log(likes)
      });
    })
    };
  };
