import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from 'firebase/compat/app';
import moment from "moment";
import { Link } from "react-router-dom";
import _ from 'lodash';

 class Comment extends Component {
  render() {
    const { displayName, comment, photoURL, createdAt } = this.props

    return (
      <div className="list-card-comment-style">
      <div className="list-card">
        <Link to={`/${displayName}`}>
        <div className="list-profile-avatar-wrapper" style={{paddingLeft: 16}}>
            <img src={photoURL} className="comment-profile-avatar" alt="profile img" />
        </div>
        </Link>
        <div className="comment-detail-wrapper">
          <div>
          <Link to={`/${displayName}`}>
          <p className="comment-display-name">{displayName}</p>
          </Link>
          <div className="comment-body-text-container">
            <p className="comment-text">{comment}</p>
            <p className="timestamp">{moment(createdAt).fromNow()}</p>
          </div>
          </div>
        </div>
            {this._removeCommentButton()}
        </div>
      </div>
    )
  }
  _removeCommentButton() {
    const { uid } = this.props.userProps
    const commenterUserUid = _.toString(uid);
    const { id, commentId} = this.props
    return this.props.uid === commenterUserUid ? (
      <div className="remove-comment-btn">
        <div style={{ flexDirection: "column" }}>
          <div>
          <button onClick={() => { firebase.database().ref(`comments/${id}/comment/${commentId}`).remove() }}>
            <i className="fa fa-trash remove-btn-icon" aria-hidden="true"></i>
          </button>
          </div>
        </div>
      </div>
    ) : (<div className="remove-btn-none"></div>);
  }

}

const mapStateToProps = state => ({
  userProps: state.userProps
});


export default connect(mapStateToProps,undefined)(Comment);
