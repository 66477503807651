// Lists Reducer
import { SET_LIKES } from '../actions/activity'


const activityDefaultState = [];

export default (state = activityDefaultState, action) => {
  switch (action.type) {
    
    case SET_LIKES:
      return action.likes;
            
    default:
      return state;
  }
};
