// Lists Reducer
import { SET_EXPLORE_USERS } from '../actions/discover'


const exploreUsersDefaultState = {};

export default (state = exploreUsersDefaultState, action) => {
  switch (action.type) {
        
    case SET_EXPLORE_USERS:
      return action.discover
    
    default:
      return state;
  }
};
