import React from "react";
import { connect } from "react-redux";
import { Grid, Col } from "react-bootstrap";
import ListCardView from "./ListCardView";
import ListIndexFilters from "./ListIndexFilters";
import listSelector from "./../../selectors/lists";
import Layout from "../layout/Layout";
import ProfileSidebar from "../publicProfile/ProfileSidebar";
import _ from "lodash";
import LinkCard from '../publicProfile/LinkCard';

const ExploreList = props => {
  const { links } = props.userProps
    // console.log(user, 'user')
    // const links = [];
    const linksArr = _.toArray(links)
  return(
  <Layout>
    <Grid>
      <div>
      <Col md={3} smHidden xsHidden style={{display: 'block'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <ProfileSidebar profileData={props.userProps} />
            </div>
            <div className='links-container-flex'>
              {
                linksArr.length === 0 ? (
                  <div/>
                ) : (
                    linksArr.map((link) => {
                      return <LinkCard key={link.id} {...link} />;
                    })
                  )
              }
            </div>
          </Col>

        {/* <Col md={3} smHidden xsHidden>
          <ProfileSidebar profileData={props.userProps} />
        </Col> */}

        <Col md={6} sm={12} xs={12}>
        <ListIndexFilters />
        <div>
          {props.lists.reverse().map(list => {
            return <ListCardView key={list.id} {...list} />;
          })}
        </div>
        </Col>
      </div>
    </Grid>
  </Layout>
)};

const mapStateToProps = state => {
  return {
    lists: listSelector(state.explore, state.filters),
    userProps: state.userProps
  };
};

export default connect(mapStateToProps)(ExploreList);
