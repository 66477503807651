import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "react-bootstrap";
import ListCard from "./ListCard";
import Layout from "../layout/Layout";

class Activity extends Component {
  å
  render(){
    // console.log(this.props, "likes")
    return(
      <Layout>
        <Grid>
        <div>
            <p>DATA COMING SOON</p>
              {this.props.likes.map(list => {
              return <ListCard key={list.id} {...list} />;
              })}
          </div>
        </Grid>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    userProps: state.userProps,
    likes: state.likes
  };
};

export default connect(mapStateToProps)(Activity);


