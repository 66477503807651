import React from "react";
import "../../styles/App.css";

const LinkCard = ({ link_title, link_url }) => (
  <div className="no-margin">
    <a href={`${link_url}`} target="_blank" rel="noopener noreferrer">
    <div className="item-card-link">

      {link_url ? (
        <div className="list-profile-link-wrapper">
        <div className="item-link-container">
          <div className="item-link-profile-link">
            <i className="fa fa-link item-link-icon-style-links" aria-hidden="true"/>
          </div>
        </div>
        </div>
      ) : (
        <div />
      )}

    <div className="item-detail-wrapper-link">
      <div>
        <p className="item-contenitem-content-link">{link_title}</p>
        {/* <p className="item-link-url item-link-wrapper-link">{link_url}</p> */}
      </div>
    </div>

    </div>
  </a>
  </div>
);

export default LinkCard;
