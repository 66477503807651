import { FETCH_USER_FOLLOWING } from '../actions/following';
const defaultUserFollowingState = [];

export default (state = defaultUserFollowingState, action) => {
  switch (action.type) {
    case FETCH_USER_FOLLOWING:
      return [
        ...state,
        action.following
      ];
    default:
      return state;
  }
};
