import firebase from 'firebase/compat/app';

export const FETCH_USER_FOLLOWERS = "FETCH_USER_FOLLOWERS";

export function fetchUserFollowers(uid) {
  return dispatch => {
    // const userID = firebase.auth().currentUser.uid;

    firebase
      .database().ref(`followers/${uid}/`).once("value")
      .then(snapshot => {
        const followers = Object.keys(snapshot.val());

        dispatch({
          type: FETCH_USER_FOLLOWERS,
          followers
        });
      })
      .catch(err => {
        //console.log(err);
      });
  };
}
