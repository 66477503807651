// Lists Reducer
import { ADD_LIST, FETCH_TIMELINE_LISTS, FETCH_PROFILE_LISTS, FETCH_MORE_LISTS, REMOVE_LIST, SET_LISTS } from '../actions/lists'


const listsReducerDefaultState = [];

export default (state = listsReducerDefaultState, action) => {
  switch (action.type) {
    case ADD_LIST:
    return [
      ...state,
      action.list
    ];

    case REMOVE_LIST:
      return state.filter(({ id }) => id !== action.id);
    
    // case EDIT_LIST:
    //   return state.map((list) => {
    //     if (list.id === action.id) {
    //       return {
    //         ...list,
    //         ...action.updates
    //       };
    //     } else {
    //       return list;
    //     };
    //   });
    
    case SET_LISTS:
      return action.lists;
      
    case FETCH_PROFILE_LISTS:
      return action.payload;
    
    case FETCH_MORE_LISTS:
      return action.lists;
      
    case FETCH_TIMELINE_LISTS:
      return action.lists;
      
    default:
      return state;
  }
};
