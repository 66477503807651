import React, { Component } from "react";
 import firebase from 'firebase/compat/app';
import _ from "lodash";

class CommentsCounter extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    };
  }


  componentDidMount() {
    this._isMounted = true;
    const postId = this.props.id;
    firebase.auth().onAuthStateChanged(user => {
    if (this._isMounted) {
      firebase.database().ref(`comments/${postId}/comment/`).on("value", snapshot => {
          let comments = snapshot.val();
          this.setState({ comments: _.toArray(comments) });
        });
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
      <div>
        <i active className="fas fa-comments" aria-hidden="true" style={{color: "#CFD8DC", fontSize: 24 }}/>
      </div>
      <div style={{marginLeft: 6}}>
        {this.state.comments !== 0 ? (<p style={{ color: "#CFD8DC", fontWeight: 'bold', fontSize: 14 }}>{this.state.comments.length}</p>) : (<div/>)}
      </div>
      </div>
    );
  }
}

export default CommentsCounter;
