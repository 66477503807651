import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";


class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: props.commentBody ? props.commentBody.comment : "",
      createdAt: props.commentBody ? moment(props.commentBody.createdAt) : moment(),
      error: ''
    };
  }
  state = {
    comment: '',
    createdAt: moment(),
    error: ''
  }

  onSubmit = () => {
    if (!this.state.comment) {
      this.setState(() => ({ error: "Can't post that" }));
    } else {
      this.setState(() => ({ error: '', comment: '' }))
      this.props.onSubmit({
        comment: this.state.comment,
        createdAt: this.state.createdAt.valueOf()
      });
    }
  };

  onChangeText = (e) => {
    const comment = e.target.value;
    this.setState(() => ({ comment }));
    
  }

  render() {
    const {photoURL} = this.props.userProps
    return (
        <div className="list-card-style">
      <div className="add-comment-container" onSubmit={this.onSubmit}>
        <div>
          <img className="add-comment-profile-avatar" src={photoURL} alt="user avatar" />
        </div>

        <div className="add-comment-input-container-style">
          <input
            type="text"
            placeholder="Add comment"
            value={this.state.comment}
            onChange={this.onChangeText}
            className="add-comment-input-textfield"
          />
        </div>
        <div className="add-comment-button-container">
          <div onClick={this.onSubmit}>
            <div className="add-comment-div-style">Post</div>
          </div>
        </div>
        <div>{this.state.error && <p>{this.state.error}</p>}</div>
      </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
    return {
      userProps: state.userProps
    };
  };

  export default connect(mapStateToProps)(CommentForm);