import database from "../firebase/firebase";
import firebase from "firebase/compat/app";
import { where } from "firebase/firestore";
import _ from "lodash";

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_LISTS = "SET_LISTS";
export const FETCH_TIMELINE_LISTS = "FETCH_TIMELINE_LISTS";
export const FETCH_PROFILE_LISTS = "FETCH_PROFILE_LISTS";
export const FETCH_MORE_LISTS = "FETCH_MORE_LISTS";
export const ADD_LIST = "ADD_LIST";
export const REMOVE_LIST = "REMOVE_LIST";
export const EDIT_LIST = "EDIT_LIST";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";

/**
 |--------------------------------------------------
 | Fetch lists for signed in user
 |--------------------------------------------------
 */

// SET LISTS
export const setLists = (lists) => ({
  type: FETCH_TIMELINE_LISTS,
  lists,
});

export const fetchTimelineLists = (uid) => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;

    return firebase
      .firestore()
      .collection("timelinesDB")
      .doc("timelines")
      .collection(`${uid}`)
      .orderBy("createdAt", "desc")
      .limit(5)
      .get()
      .then((docSnapshot) => {
        const lists = [];
        docSnapshot.forEach((doc) => {
          lists.push(doc.data());
        });

        dispatch(setLists(lists));
      })
      .catch((e) => console.log(e));
  };
};

/**
 |--------------------------------------------------
 | Fetch lists for signed in user
 |--------------------------------------------------
 */

// GETS FRIEND OR PUBLIC PROFILE BY PASSING USERS UID // USED by publicProfile
// export function fetchUserProfileLists(uid) {
//   return dispatch => {
//     firebase.database().ref(`users/${uid}/`).on("value", snapshot => {
//       let payload = snapshot.val();
//       dispatch({
//         type: FETCH_PROFILE_LISTS,
//         payload
//       });
//     });
//   };
// }

// ADD_LIST
export const addList = (list) => ({
  type: ADD_LIST,
  list,
});

export const startAddList = (listData = {}) => {
  const { currentUser } = firebase.auth();

  return (dispatch) => {
    const newListKey = firebase
      .database()
      .ref()
      .child("lists")
      .push().key;

    const {
      title = "",
      items = "",
      createdAt = 0,
      likes = 0,
      img_url = "",
      slug = "",
      photoURL = firebase.auth().currentUser.photoURL,
      uid = firebase.auth().currentUser.uid,
      displayName = firebase.auth().currentUser.displayName,
      id = newListKey,
    } = listData;

    const list = {
      title,
      id,
      items,
      likes,
      uid,
      createdAt,
      slug,
      img_url,
      photoURL,
      displayName,
    };

    firebase
      .firestore()
      .collection("postsDB")
      .doc(`${id}`)
      .set(list, { merge: true });

    firebase
      .firestore()
      .collection("timelinesDB")
      .doc("timelines")
      .collection(`${currentUser.uid}`)
      .doc(`${id}`)
      .set(list, { merge: true });

    firebase
      .firestore()
      .collection("usersDB")
      .doc(`${currentUser.uid}`)
      .collection("posts")
      .doc(`${id}`)
      .set(list, { merge: true })
      .then((ref) => {
        dispatch(
          addList({
            id: ref,
            ...list,
          })
        );
      });
  };
};

// REMOVE_LIST
export const removeList = ({ id } = {}) => ({
  type: REMOVE_LIST,
  id,
});

export const startRemoveList = ({ id } = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    firebase
      .firestore()
      .collection("timelinesDB")
      .doc("timelines")
      .collection(`${currentUser.uid}`)
      .doc(`${id}`)
      .delete()
      .then(() => {
        dispatch(removeList({ id }));
      })
      .then(() => {
        const followersRef = firebase
          .database()
          .ref(`followers/${currentUser.uid}`);

        followersRef.on("value", (snapshot) => {
          const followers = Object.keys(snapshot.val());

          followers.forEach((key) =>
            firebase
              .firestore()
              .collection("timelinesDB")
              .doc("timelines")
              .collection(`${key.uid}`)
              .doc(`${id}`)
              .delete()
              .then(() => {
                dispatch(removeList({ id }));
              })
          );
        });
      })
      .then(() => {
        return firebase
          .firestore()
          .collection("usersDB")
          .doc(`${currentUser.uid}`)
          .collection("posts")
          .doc(`${id}`)
          .delete();
      });
  };
};

// EDIT_LIST
export const editList = (id, updates) => ({
  type: EDIT_LIST,
  id,
  updates,
});

export const startEditList = (id, updates) => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    firebase
      .database()
      .ref(`followers/${user.uid}`)
      .once("value", (snapshot) => {
        // Read followers keys and turn into hash
        const followers = Object.keys(snapshot.val());

        followers.forEach((key) =>
          firebase
            .firestore()
            .collection("timelinesDB")
            .doc("timelines")
            .collection(`${key}`)
            .doc(`${updates.id}`)
            .set(updates, { merge: true })
        );
      })
      .then(() => {
        firebase
          .firestore()
          .collection("timelinesDB")
          .doc("timelines")
          .collection(`${user.uid}`)
          .doc(`${updates.id}`)
          .set(updates, { merge: true });

        return firebase
          .firestore()
          .collection("usersDB")
          .doc(`${user.uid}`)
          .collection("posts")
          .doc(`${updates.id}`)
          .set(updates, { merge: true })
          .then(() => {
            dispatch(editList(updates));
          })
          .catch((e) => console.log(e));
      });
  };
};
