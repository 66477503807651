import firebase from 'firebase/compat/app';

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */
export const FETCH_FEATURED_FRIENDS = "FETCH_FEATURED_FRIENDS";


/**
 |--------------------------------------------------
 | Fetch User profile
 |--------------------------------------------------
 */

export function fetchFeaturedFriends(uid) {
  return dispatch => {
    firebase.database().ref(`featured-friends/${uid}/friends`).on("value", snapshot => {
      let payload = snapshot.val();
      console.log(payload, "featured freidns")
        dispatch({
          type: FETCH_FEATURED_FRIENDS,
          payload
        });
      });
  };
}
