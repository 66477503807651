import React, { Component } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import firebase from 'firebase/compat/app';
import { Grid, Col, Form } from "react-bootstrap";
import _ from 'lodash'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  
  
  width: '100%',
  // change background colour if dragging
  background: isDragging ? "#EEEEEE" : "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#FFFFFF" : "f5f5f7",
  width: '100%'
});

class SortableLinks extends Component {
  constructor(props){
    super(props)
    this.state = {
      items:[],
      data: []
    }
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  state = {
    items: [],
    error: ''
  }


  componentDidMount() {
    const { uid } = this.props.userProps;
    firebase
      .database()
      .ref(`users/${uid}/links`)
      .on('value', (snapshot) => {
        let data = _.toArray(snapshot.val());
        this.setState({items: _.toArray(data)});
      });
  }

  handleAddItem(link_title, link_url) {

    if (!link_title) {
      return 'Enter valid value to add item';
    } else if (this.state.items.indexOf(link_title) > -1) {
      return 'This item already exists';
    }

    let item = {
      link_title,
      link_url,
      id: firebase.database().ref().push().key
    }

    this.setState((prevState) => {
      return {
        items: prevState.items.concat(item)
      };
    })

    const {uid} = this.props.userProps;
    let items = this.state.items;
    firebase.database().ref(`users/${uid}/links/`).update(items);

  }

  removeItem(item) {
    const updatedItemCount = this.state.items.filter(items => {
      return items !== item
    })
    this.setState({
      items: [...updatedItemCount]
    })

  }

  removeFromDatabase(){

  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });

    const {uid} = this.props.userProps;
    // let items_ = this.state.items;
    firebase.database().ref(`users/${uid}/links/`).update(items);

  }

  componentDidUpdate() {
    const {uid} = this.props.userProps;
    let items = this.state.items;
    firebase.database().ref(`users/${uid}/links/`).set(items);
  }


  onKeyPress(e) {
    if (e.which === 13 /* Enter key */) {
      e.preventDefault();
    }
  }

  render(){
    return(
        <div>
          <div>
            <p><strong>Profile Links</strong> (Add links then drag and drop to arrange)</p>
            {
              this.state.items.length === 0 ? (
                <p className="no-items-state-style">No Links to display <span role="img" aria-label="sad emoji">😒</span></p>
              ) : (
                  
                <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className="item-card"
                            >
                                  {item.link_url ? (
                                    <div className="list-profile-link-wrapper">
                                      <div className="item-link-container">
                                        <div className="item-link">
                                          <i
                                            className="fa fa-link item-link-icon-style"
                                            aria-hidden="true"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
        
                                  <div className="item-detail-wrapper">
                                    <div>
                                      <p className="item-content">{item.link_title}</p>
                                      <p className="item-link-url item-link-wrapper">
                                        {item.link_url}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="item-link-container">
                                    <div className="delete-link-icon">
                                      <div
                                        index={index}
                                        onClick={(e) => this.removeItem(item)}
                                      >
                                        <i className="fas fa-trash-alt delete-icon"></i>
                                      </div>
                                    </div>
                                  </div>
        
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              </div>
         )
        }
        </div>
        <AddItemForm items={this.props.items} onSubmit={this.handleAddItem}/>
        </div>
    )
  }
}


class AddItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items: this.props.items ? this.props.items : [],
      link_title: props.item ? props.item.link_title : '',
      link: props.item ? props.item.link : '',
      error: undefined
    };
  }

  state = {
    link_title: '',
    link_url: '',
    error: ''
  }

  onContentChange = (e) => {
    const link_title = e.target.value;
    this.setState(() => ({ link_title }));
  }
  
  onLinkChange = (e) => {
    const link_url = e.target.value;
    this.setState(() => ({ link_url }));
  }

  onSubmit = (e) => {
    e.preventDefault();
    
    if (!this.state.link_title) {
      this.setState(() => ({ error: 'Please provide item' }))
    } else {
      this.setState(() => ({ error: '', link_title: '', link_url: '' }))
      this.props.onSubmit(this.state.link_title, this.state.link_url)
    }
  }

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
}
  
  render() {
    return (
      <div className="list-card-style">
        {this.state.error && <p>{this.state.error}</p>}
        <Form autoComplete="off" onKeyPress={this.onKeyPress} onSubmit={this.onSubmit}>
        
        
          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
            <div style={{marginLeft: 16}}>
              <i className="fas fa-pencil-alt"/>
            </div>
            <div className="add-item-input">
              <input 
                type="text"
                placeholder="Link title"
                value={this.state.link_title}
                onChange={this.onContentChange}
                style={{width: '100%'}}
                autoComplete="off" />
            </div>
          </div>
          

          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
            <div style={{marginLeft: 16}}>
              <i className="fa fa-link" aria-hidden="true"/>
            </div>
            <div className="add-item-input">
              <input
                type="text"
                placeholder="Past link here (Where does this link to?)"
                value={this.state.link_url}
                onChange={this.onLinkChange}
                style={{width: '100%'}}
                autoComplete="off"
              />
            </div>
          </div>
  
          <div onClick={this.onSubmit}  className="add-item-btn-container">
            <div className="add-item-btn">
              <strong>+ Add Item</strong>
            </div>
          </div>
            
          </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProps: state.userProps,
  authUser: state.sessionState.authUser,
  user: state.user
});


export default connect(mapStateToProps, undefined)(SortableLinks);


const styles = {
  privateInfoContainer: {
    paddingTop: 20,
    // borderColor: '#CCCCCC',
    // borderTopWidth: 2,
  },
  privateInfoText:{
    color: '#9E9E9E',
    fontWeight: 'bold'
  },
  userAccountTextInput: {
    flexDirection: 'row',
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: '#FFFFFF',
    height: 40,
    flex: 1,
    borderColor: '#CCCCCC',
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    paddingTop: 20,
    fontWeight: 'bold'
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#304FFE',
    padding: 10,
    margin: 10,
    backgroundColor: '#304FFE',
    height: 40,
  },
  submitButtonText: {
    color: '#ffffff',
    textAlign: 'center'
  },
}
