import database  from '../firebase/firebase'
import firebase from 'firebase/compat/app';

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */
export const SET_COMMENTS = 'SET_COMMENTS';

export const ADD_COMMENT = 'ADD_COMMENT';

/**
|--------------------------------------------------
| Actions
|--------------------------------------------------
*/
//${currentUser.uid}

export function getComments( id ) {
  //const { currentUser } = firebase.auth();
  return dispatch => {
    firebase.database().ref(`/comments/${id}/comment`).on('value', snapshot => {
      dispatch({
        type: SET_COMMENTS,
        payload: snapshot.val()
      })
    })
  }
//   console.log(snapshot.val(), "COMMENTS PAYLOAD")
}

// export function savePost(post) {
//   return dispatch => database.push(post)
// }

// export function deletePost(id) {
//   return dispatch => database.child(id).remove()
// }






// ADD_COMMENT
export const addComment = (id, commentBody) => ({
  type: ADD_COMMENT,
  id,
  commentBody
});

export const startAddComment = (id, commentData = {}) => {
  return (dispatch) => {
    const newCommentKey = firebase.database().ref(`comments/${id}`).child('comment').push().key
    const {
      comment = '',
      createdAt = 0,
      likes = 0,
      commentId = newCommentKey, 
      photoURL = firebase.auth().currentUser.photoURL,
      uid = firebase.auth().currentUser.uid,
      displayName = firebase.auth().currentUser.displayName
    } = commentData;
    
    const commentBody = { displayName, uid, id, photoURL, comment, createdAt, likes, commentId };
    
    let updates = {}
    
    updates[`/comments/${id}/comment/${newCommentKey}`] = commentBody
    
    firebase.database().ref().update(updates).then((ref) => {
      dispatch(addComment({
        // id: ref.key,
        ...commentBody
      }));
    });
  };
};
