import React, {
  Component,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Grid, Col, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import { fetchPublicProfileLikes } from "../../actions/likes";
import moment from "moment";
import Layout from "../layout/Layout";
import _ from "lodash";
import { startRemoveList, startEditList } from "../../actions/lists";
import "../../styles/App.css";
import ListItem from "./ListItem";
import { Link } from "react-router-dom";
import CommentsView from "../comments/CommentsView";
import CommentsList from "../comments/CommentsList";
import ProfileSidebar from "../publicProfile/ProfileSidebar";
import CommentsCounter from "../timeline/CommentsCounter";
import EditListView from "../lists/EditListView";
import LinkCard from "../publicProfile/LinkCard";
import HeaderTags from "../layout/HeaderTags";
import { where } from "firebase/firestore";
import customData from "./customData.json";
import img_context_app_store from "../../assets/images/app_store.png";
import img_context_google_play from "../../assets/images/google_play.png";

const Liked = () => (
  <div>
    <i className="likes fa fa-heart" aria-hidden="true" />
  </div>
);

const NotLiked = () => (
  <div>
    <i
      className="fa fa-heart"
      aria-hidden="true"
      style={{ color: "#CFD8DC", fontSize: 24 }}
    />
  </div>
);

class List extends Component {
  render() {
    const { photoURL, displayName } = this.props.profileData;
    const { title, createdAt, items, img_url } = this.props;
    const itemsArray = _.toArray(items);

    return (
      <div>
        <div className="list-card-style">
          {img_url ? (
            <div>
              <img
                src={img_url}
                alt="post"
                style={{
                  boder: "none",
                  width: "100%",
                  height: "100%",
                  borderTopRightRadius: 22,
                  borderTopLeftRadius: 22,
                }}
              />
            </div>
          ) : (
            <div />
          )}

          <div className="list-card">
            <div
              className="list-profile-avatar-wrapper"
              style={{
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 8,
              }}
            >
              <Link to={{ pathname: `/${displayName}/` }}>
                <img
                  src={photoURL}
                  className="list-profile-avatar"
                  alt="profile img"
                />
              </Link>
            </div>

            <div className="list-detail-wrapper">
              <div>
                <Link to={{ pathname: `/${displayName}/` }}>
                  <p className="list-user-name" style={{ color: "#666" }}>
                    {displayName}
                  </p>
                </Link>
                <div style={{ marginTop: 4, flex: 1 }}>
                  <p className="list-title">{title}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    marginTop: 4,
                  }}
                >
                  <div className="impressions-link-container">
                    <div style={{ marginRight: 4, marginLeft: 8 }}>
                      <i
                        class="fa fa-link"
                        style={{ color: "#666", fontSize: 10 }}
                      />
                    </div>
                    <div style={{ marginRight: 8, marginTop: 2 }}>
                      <p
                        style={{
                          fontSize: 10,
                          color: "#666",
                          fontWeight: "bold",
                        }}
                      >
                        {itemsArray.length}
                      </p>
                    </div>
                  </div>
                  <div
                    className="impressions-link-container"
                    style={{ marginLeft: 4 }}
                  >
                    <div style={{ marginRight: 4, padding: 4 }}>
                      <p
                        style={{
                          fontSize: 10,
                          color: "#666",
                          fontWeight: "bold",
                        }}
                      >
                        {moment(createdAt).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="list-items-wrapper">
          {itemsArray.length === 0 ? (
            <div>
              <p>
                No links yet{" "}
                <span role="img" aria-label="sad emoji">
                  😒
                </span>
              </p>
            </div>
          ) : (
            itemsArray.map((props, id) => {
              return <ListItem key={id} {...props} />;
            })
          )}
        </div>
      </div>
    );
  }
}

const ListDetail = (props) => {
  let isMounted = false;
  const [listsArr, setLists] = useState({});
  const [profileData, setProfileData] = useState({});
  const [listObj, setListObj] = useState({});
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [impressionModal, setShowImpressionModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [user, setUser] = useState(props.userProps);
  const [impressions, setImpressions] = useState(0);
  const [views, setViewers] = useState([]);
  const [likes, setLikes] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  // useEffect(() => {
  //   _.toArray(customData).forEach(obj => {
  //     firebase.firestore()
  //       .collection('postsDB')
  //       .doc(`${obj.id}`)
  //       .set({...obj})
  //       .then(docRef => console.log("Document written with ID: ", docRef.title))
  //       .catch(error => console.error("Error adding document: ", error))
  //   });
  // }, []);

  // Get lists info
  // User props and set list data
  useEffect(() => {
    const username = props.match.params.uid;
    const { slug } = props.match.params;

    isMounted = true;

    if (isMounted) {
      setIsLoading(false);

      let userRef = firebase.firestore().collection("usersDB");
      userRef
        .where("displayName", "==", username)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function(doc) {
            const profileData = doc.data();
            setProfileData(profileData, slug);
            callListDetails(profileData.uid, slug);
            setIsLoading(false);
          });
        })
        .catch((e) => console.log(e));
    }
  }, []);

  const callListDetails = useCallback(
    (uid, slug) => {
      const listRef = firebase
        .firestore()
        .collection("usersDB")
        .doc(`${uid}`)
        .collection("posts");

      listRef
        .where("slug", "==", slug)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            const listObj = doc.data();
            setListObj(listObj);
            setPostImpression(listObj.uid, listObj.id);
            setPostViews(listObj);
          });
        })
        .catch((e) => console.log(e));
    },
    [profileData]
  );

  const getImpressions = useCallback(() => {
    const insightsRef = firebase
      .firestore()
      .collection("analytics")
      .doc(`${profileData.uid}`)
      .collection("post")
      .doc(`${listObj.id}`)
      .get();

    insightsRef
      .then((docSnapshot) => {
        let impressionsNumber = docSnapshot.data().impressions
          ? docSnapshot.data().impressions
          : 0;
        let likesNumber = docSnapshot.data().likes
          ? docSnapshot.data().likes
          : 0;
        setImpressions(impressionsNumber);
        setLikes(likesNumber);
      })
      .catch((e) => console.log(e));
  }, [listObj.id]);

  const setPostViews = useCallback(async (listObject) => {
    try {
      let data = {
        displayName: props.userProps.displayName,
        photoURL: props.userProps.photoURL,
        uid: props.userProps.uid,
        verified: props.userProps.verified,
        city: props.userProps.city ? props.userProps.city : "unknown",
      };

      const uidViewsRef = firebase
        .firestore()
        .collection("analytics")
        .doc(`${listObject.uid}`)
        .collection("post")
        .doc(`${listObject.id}`)
        .collection("views")
        .doc(`${props.userProps.uid}`);

      uidViewsRef.get().then((docSnapshot) => {
        if (docSnapshot.exists || listObj.uid === props.userProps.uid) {
          return;
        } else {
          uidViewsRef.set(data, { merge: true });
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  }, []);

  const setLikeCall = useCallback(async (listObject) => {
    try {
      let data = {
        displayName: props.userProps.displayName,
        photoURL: props.userProps.photoURL,
        uid: props.userProps.uid,
        verified: props.userProps.verified,
        city: props.userProps.city ? props.userProps.city : "unknown",
      };

      const uidViewsRef = firebase
        .firestore()
        .collection("analytics")
        .doc(`${listObject.uid}`)
        .collection("post")
        .doc(`${listObject.id}`)
        .collection("likes")
        .doc(`${props.userProps.uid}`);

      uidViewsRef.get().then((docSnapshot) => {
        if (docSnapshot.exists || listObject.uid === props.userProps.uid) {
          // Add heart state to chagne color and remove like from likers array
          // setLikedList(true)
          // return
        } else {
          uidViewsRef.set(data, { merge: true });

          firebase
            .firestore()
            .collection("analytics")
            .doc(`${listObject.uid}`)
            .collection("post")
            .doc(`${listObject.id}`)
            .set(
              { likes: firebase.firestore.FieldValue.increment(1) },
              { merge: true }
            );
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  }, []);

  const setPostImpression = useCallback(
    async (uid, id) => {
      try {
        firebase
          .firestore()
          .collection("analytics")
          .doc(`${uid}`)
          .collection("post")
          .doc(`${id}`)
          .set(
            { impressions: firebase.firestore.FieldValue.increment(1) },
            { merge: true }
          );
      } catch (error) {
        console.log(error, "error");
      }
    },
    [listObj.id]
  );

  const handleShow = () => {
    setShow(true);
  };
  const handleHide = () => {
    setShow(false);
  };

  //  get and set viewer data array
  const getViewerData = useCallback(() => {
    const viewersRef = firebase
      .firestore()
      .collection("analytics")
      .doc(`${listObj.uid}`)
      .collection("post")
      .doc(`${listObj.id}`)
      .collection("views");

    viewersRef
      .get()
      .then((docSnapshot) => {
        let viewerArr = [];
        docSnapshot.forEach((doc) => {
          viewerArr.push(doc.data());
        });

        setViewers(viewerArr);
      })
      .catch((e) => console.log(e));
  }, [listObj.id, listObj.uid]);
  //  get and set viewer data array

  const showImpressionModal = () => {
    getImpressions();
    getViewerData();
    setShowImpressionModal(!impressionModal);
  };

  const EditListButton = () => {
    const loggedInUserUID = _.toPlainObject(props.userProps);
    const { uid } = profileData;
    const slug = props.match.params.slug;

    return loggedInUserUID.uid === uid ? (
      <div>
        <Button style={{ marginBottom: "10px" }} onClick={handleShow}>
          <div className="add-comment-div-style">
            <i className="far fa-edit" /> Edit
          </div>
        </Button>
      </div>
    ) : (
      <div />
    );
  };

  const ImpressionsButton = () => {
    const loggedInUserUID = _.toPlainObject(props.userProps);
    const { uid } = profileData;

    return loggedInUserUID.uid === uid ? (
      <div>
        <Button style={{ marginBottom: "10px" }} onClick={showImpressionModal}>
          <div className="add-comment-div-style">
            <i className="fa-chart" /> View Insights
          </div>
        </Button>
      </div>
    ) : (
      <div />
    );
  };

  const shareTierlyButton = () => {
    const loggedInUserUID = _.toPlainObject(props.authUser);
    const { uid } = profileData;
    return loggedInUserUID.uid === uid ? (
      <div
        onClick={() => {
          props.startShareTierly({ id: props.match.params.id });
          props.history.push("/");
        }}
      >
        <Button style={{ marginBottom: "10px" }}>
          {" "}
          <div className="add-comment-div-style">
            <i class="fas fa-share" /> Share{" "}
          </div>{" "}
        </Button>
      </div>
    ) : (
      <div />
    );
  };

  const pageTitle = listObj.displayName + " | " + listObj.title;
  const linksArr = _.toArray(profileData.links);
  // const pageTitle = `<meta name="twitter:description" content="${pageTitle}"/>`

  return (
    <Layout>
      <HeaderTags
        title={pageTitle}
        description={listObj.title}
        name="Tierly"
        type="article"
      />
      <Grid>
        <div>
          <Col md={3} sm={12} xs={12} style={{ display: "block" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ProfileSidebar profileData={profileData} />
            </div>
            <div
              className="links-container-flex"
              style={{ display: linksArr.length === 0 ? "none" : "flex" }}
            >
              {linksArr.length === 0 ? (
                <div />
              ) : (
                linksArr.map((link) => {
                  return <LinkCard key={link.id} {...link} />;
                })
              )}
            </div>
            <Col className="no-margin" md={12} xsHidden smHidden>
              <div style={{ marginTop: 12, paddingRight: 4, paddingLeft: 4 }}>
                <p>Download now for</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 4,
                  }}
                >
                  <div>
                    <a
                      href="https://apps.apple.com/us/app/tierly/id1513435561"
                      target="new"
                    >
                      <img
                        src={img_context_app_store}
                        className="img-responsive"
                        alt="logo"
                        draggable="false"
                      />
                    </a>
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=dev.varsoft.Tierly&hl=en_US&gl=US"
                      target="blank"
                    >
                      <img
                        src={img_context_google_play}
                        className="img-responsive"
                        alt="logo"
                        draggable="false"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Col>

          <Col md={6} sm={12} xs={12}>
            <List
              {...listObj}
              profileData={profileData}
              userProps={props.userProps}
              id={listObj.id}
            />

            <div className="likes_share_comment_container">
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                {/* <LikeButton id={props.match.params.id}/> */}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div onClick={() => setLikeCall(listObj)}>
                    <NotLiked />
                  </div>
                  <div style={{ marginLeft: 6 }}>
                    {/* {likesCount !== 0 ? (<p style={{ color: "#CFD8DC", fontWeight: 'bold', fontSize: 14 }}>{likesCount.length}</p>) : (<div/>)} */}
                  </div>
                </div>
              </div>

              <div style={{ justifyContent: "center" }}>
                <CommentsCounter id={listObj.id} />
              </div>

              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <div
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `https://tier.ly/${listObj.displayName}/${listObj.slug}`
                      )
                      .then(() =>
                        alert("Tierly link copied, share it anywhere!")
                      )
                  }
                >
                  <i
                    active
                    className="fas fa-share"
                    aria-hidden="true"
                    style={{ color: "#CFD8DC", fontSize: 24 }}
                  />
                </div>
              </div>
            </div>

            {props.authUser ? <CommentsView id={listObj.id} /> : <div />}

            <CommentsList profileData={profileData} id={listObj.id} />
          </Col>

          {props.authUser ? (
            <>
              {EditListButton()}
              {ImpressionsButton()}
            </>
          ) : (
            <></>
          )}
        </div>
      </Grid>

      <div>
        <Modal
          {...props}
          show={impressionModal}
          onHide={() => setShowImpressionModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-lg"
              style={{ paddingBottom: 16 }}
            >
              <i class="fa-bar-chart" />
              <strong>Tierly Insights</strong>
              <p style={{ fontSize: 14 }}>See more details about visitors</p>
            </Modal.Title>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div className="metrics-container">
                  <p style={{ fontSize: 12, fontWeight: "bold" }}>
                    Impressions
                  </p>
                  <p style={{ fontSize: 24, fontWeight: "bold" }}>
                    {impressions}
                  </p>
                </div>
                <div className="metrics-container">
                  <p style={{ fontSize: 12, fontWeight: "bold" }}>Views</p>
                  <p style={{ fontSize: 24, fontWeight: "bold" }}>
                    {views.length}
                  </p>
                </div>
                <div className="metrics-container">
                  <p style={{ fontSize: 12, fontWeight: "bold" }}>Likes</p>
                  <p style={{ fontSize: 24, fontWeight: "bold" }}>{likes}</p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 8,
                  marginHorizontal: 24,
                }}
              >
                <div style={{ marginRight: 8, marginTop: 2 }}>
                  <i
                    class="fa fa-eye"
                    style={{ color: "#212121", fontSize: 16 }}
                  />
                </div>
                <div>
                  <p style={{ fontWeight: "bold" }}>Views</p>
                </div>
              </div>

              <div style={{ marginTop: 12 }}>
                {views.map((viewer) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 12,
                      }}
                    >
                      <div>
                        <Link to={`/${viewer.displayName}`}>
                          <div className="viewer-profile-avatar-wrapper">
                            <img
                              src={viewer.photoURL}
                              className="comment-profile-avatar"
                              alt="profile img"
                            />
                          </div>
                        </Link>
                      </div>
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: 14 }}>
                          {viewer.displayName}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal.Header>
        </Modal>
      </div>

      <Col md={12} xsHidden smHidden>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            width: 600,
          }}
        >
          <div style={{ display: "flex", width: 600 }}>
            <Modal
              // style={{maxWidth: 600}}
              backdrop="static"
              keyboard={false}
              {...props}
              show={show}
              onHide={handleHide}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-lg"
                  style={{ paddingBottom: 16 }}
                >
                  Edit tierly
                </Modal.Title>
                <EditListView list={listObj} history={props.history} />
              </Modal.Header>
            </Modal>
          </div>
        </div>
      </Col>

      <Col mdHidden xs={12} sm={12}>
        <Modal
          // style={{maxWidth: 600}}
          backdrop="static"
          keyboard={false}
          {...props}
          show={show}
          onHide={handleHide}
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-lg"
              style={{ paddingBottom: 16 }}
            >
              Edit tierly
            </Modal.Title>
            <EditListView list={listObj} history={props.history} />
          </Modal.Header>
        </Modal>
      </Col>
    </Layout>
  );
};

const mapStateToProps = (state, props) => ({
  authUser: state.sessionState.authUser,
  lists: state.lists,
  userProps: state.userProps,
  likes: state.likes,
});

const mapDispatchToProps = (dispatch) => ({
  startRemoveList: (data) => dispatch(startRemoveList(data)),
  fetchPublicProfileLikes: (uid, id) =>
    dispatch(fetchPublicProfileLikes(uid, id)),
  startEditList: () => dispatch(startEditList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListDetail);
