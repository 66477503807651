import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from 'firebase/compat/app';
import _ from "lodash";
import { fetchPublicProfileLikes } from "../../actions/likes";

const Liked = () => (
  <div>
    <i className="likes fa fa-heart" aria-hidden="true" />
  </div>
);

const NotLiked = () => (
  <div>
    <i className="fa fa-heart" aria-hidden="true" style={{color: "#CFD8DC", fontSize: 24 }} />
  </div>
);

class LikeButton extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      liked: false,
      likesData: {},
      likesArr: []
    };
   this.handleLiked = this.handleLiked.bind(this)
   this.handleLikedStateButton = this.handleLikedStateButton.bind(this)
  }
  
  componentDidMount() {
    this._isMounted = true;
    const postId = this.props.id;
    
    firebase.auth().onAuthStateChanged(user => {
    if (this._isMounted) {
      firebase.database().ref(`likes/${postId}/`).on("value", snapshot => {
          let likesArr = snapshot.val();
          this.setState({ likesArr: _.toArray(likesArr) });
        });
      }
    })
  }


  // componentWillReceiveProps(nextProps) {
  //   console.log('componentWillReceiveProps', nextProps);
  //   if (this.props !== nextProps) {
  //    this.setState(nextProps);
  //   }
  //  }

  handleLikedStateButton() {
    if (this.state.liked === false) {
      this.setState({
        liked: !this.state.liked
      });
    } else if (this.state.liked === true) {
      this.setState({
        liked: false
      });
    }
  }


  handleLiked() {

    const { id } = this.props
    
    const uid = firebase.auth().currentUser.uid
    const displayName = firebase.auth().currentUser.displayName

    // const likeRef = firebase.database().ref(`/liked/${uid}/${id}/`);

    // const { liked } = this.state

    if (this.state.liked === false) {
      this.setState({
        liked: !this.state.liked
      });

      // likeRef.on('child_added', (snapshot) => {});

      const likeBody = true;

      let updates = {}

        // Send like to timeline post
        updates[`/timeline/${uid}/${id}/liked/` + uid] = likeBody
        // Sends like to Own Current users liked node
        updates[`/users/${displayName}/liked/${id}`] = likeBody
        // Sends like to Liked Top Node
        updates[`/liked/${uid}/${id}`] = likeBody
        // Sends like to Lists Top Node
        // updates[`lists/${id}/liked/` + uid] = likeBody

        return firebase.database().ref().update(updates)

    } else if (this.state.liked === true) {
      
      this.setState({
        liked: false
      });

      // likeRef.on('child_removed', (snapshot) => {});

        return firebase.database().ref(`/timeline/${uid}/${id}/liked/` + uid).remove().then(() => {
        return firebase.database().ref(`/liked/${uid}/${id}`).remove().then(() => {
        return firebase.database().ref(`/user/${displayName}/liked/${id}`).remove()
        })
      })
    }

  }

  likeButton() {
    const id = this.props.id
    const likesArr  = _.toArray(this.props.likes)

    var filteredLike = likesArr.find(function(element) {
        return element === id;
    });

    return (filteredLike || this.state.liked ? <Liked /> : <NotLiked/> );
  }


  render() {
    const likesCount = _.toArray(this.state.likesArr)
    
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center"}}>  
        <div>
          {this.likeButton()}
        </div>
        <div style={{marginLeft: 6}}>
          {likesCount !== 0 ? (<p style={{ color: "#CFD8DC", fontWeight: 'bold', fontSize: 14 }}>{likesCount.length}</p>) : (<div/>)}
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state, props) => ({
  likes: state.likes, 
});

const mapDispatchToProps = dispatch => ({
  fetchPublicProfileLikes: (uid, id) => dispatch(fetchPublicProfileLikes(uid, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LikeButton);




