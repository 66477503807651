import React, { Component } from "react";
import { connect } from "react-redux";
// import { firebase } from "../../firebase/firebase";
import firebase from 'firebase/compat/app';
import { Grid, Col } from "react-bootstrap";
import PasswordChangeForm from "../passwordChange";
import Layout from "../layout/Layout";
import AccountForm from "./AccountForm";
import SortableLinks from "./SortableLinks";

class AccountPage extends Component {
  render(){
    const { displayName } = this.props.userProps
    return(
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={styles.leftTitleContainer}>@{displayName}</p>

            <div style={{marginTop: 20}}>
                <ImageSelectorComponent />
            </div>

              
            <div style={{marginTop: 20}}>
              <AccountForm />
            </div>
                            
            {/* <div style={{marginTop: 20, marginBottom: 20}}>
              <SortableLinks />
            </div> */}
  
            <div style={{marginTop: 20, marginBottom: 20}}>
              <PasswordChangeForm/>
            </div>
        </div>
    )
  }
}


class ImageSelectorComponent extends Component {
  constructor(state) {
    super(state);
    this.state = {
      picture: null,
      pictureURL: null
    };
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ pictureURL: user.photoURL });
    });
  }
  render() {
    const { input } = this.props;
    return (
      <div style={{marginBottom: 20}}>
        <form>
          <Col md={2} sm={6} className="no-margin">
          <PreviewPicture pictureURL={this.state.pictureURL} />
          </Col>
          <Col md={10} sm={6} className="no-margin">
          <p>Change Profile Photo</p>
          <input type="file" onChange={event => { this.displayPicture(event) }} {...input} />
          </Col>
        </form>
      </div>
    );
  }

  displayPicture(event) {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        picture: file,
        pictureURL: reader.result
      });
    };

    const { uid } = firebase.auth().currentUser;
    const folderName = `${uid}`;
    const imageName = `${uid}.jpg`;

    reader.readAsDataURL(file);

    var metadata = {
      contentType: "image/jpeg"
    };

    const imageRef = firebase.storage().ref(`profile_images/${folderName}/${imageName}`);

    imageRef.put(file, metadata).then(() => {
        return imageRef.getDownloadURL();
      })
      .then(url => {
        firebase.auth().onAuthStateChanged(user => { 
          user.updateProfile({photoURL: url})
        })
        firebase.firestore().collection('usersDB').doc(`${uid}`).set({photoURL: url}, {merge: true})
        firebase.database().ref(`/users/${uid}`).child("photoURL").set(url)
      })
      .then(() => alert("Image uploaded"))
      .catch(error => {})
  }
}

const PreviewPicture = props => {
  const { pictureURL } = props;
  return (
    <div className="account-img-wrapper">
      <img src={pictureURL}  className="account-user-img" alt="user avatar" draggable={false} />
    </div>
  )}

const mapStateToProps = state => ({
  userProps: state.userProps,
  authUser: state.sessionState.authUser
});


export default connect(mapStateToProps, undefined)(AccountPage);


const styles = {
  privateInfoContainer: {
    paddingTop: 20,
    // borderColor: '#CCCCCC',
    // borderTopWidth: 2,
  },
  privateInfoText:{
    color: '#9E9E9E',
    fontWeight: 'bold'
  },
  userAccountTextInput: {
    flexDirection: 'row',
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: '#FFFFFF',
    height: 40,
    flex: 1,
    borderColor: '#CCCCCC',
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    paddingTop: 20,
    fontWeight: 'bold'
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#304FFE',
    padding: 10,
    margin: 10,
    backgroundColor: '#304FFE',
    height: 40,
  },
  submitButtonText: {
    color: '#ffffff',
    textAlign: 'center'
  },
}
