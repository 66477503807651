import React, { Component } from "react";
import tierlySplshLogo from './assets/images/_logo@2x.png'

class TierlyAppSplash extends Component {
  render(){
    return(
      <div className="splash-logo-container">
        <img className="splash-logo" src={tierlySplshLogo} alt="welcome"/>
      </div>
    )
  }
}

export default TierlyAppSplash;