import React, { Component, useState } from "react";
import firebase from "firebase/compat/app";
import { connect } from "react-redux";
import { Modal, Col, Button } from "react-bootstrap";
import img_context_app_store from "../../assets/images/app_store.png";
import img_context_google_play from "../../assets/images/google_play.png";

const FollowButtonFake = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="profile-user-stats-wrapper">
        <div onClick={() => setShow(!show)}>
          <div className="follow-btn">
            <p className="follow-button-text">Subscribe</p>
          </div>
        </div>
      </div>
      <div>
        <Modal {...props} show={show} onHide={() => setShow(!show)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
              Download app
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ width: "100%" }}>
            To access all features download the app from the following places!
            <div style={{ marginTop: 12, paddingRight: 4, paddingLeft: 4 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 4,
                }}
              >
                <div>
                  <a
                    href="https://apps.apple.com/us/app/tierly/id1513435561"
                    target="new"
                  >
                    <img
                      src={img_context_app_store}
                      className="img-responsive"
                      alt="logo"
                      draggable="false"
                    />
                  </a>
                </div>
                <div style={{ marginLeft: 8 }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=dev.varsoft.Tierly&hl=en_US&gl=US"
                    target="blank"
                  >
                    <img
                      src={img_context_google_play}
                      className="img-responsive"
                      alt="logo"
                      draggable="false"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default FollowButtonFake;
