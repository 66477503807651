import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { startEditList } from "../../actions/lists";
import ListEditForm from "./ListEditForm";

class EditListView extends Component {
  render() {
    return (
      <div>  
        <ListEditForm
          list={this.props.list}
          onSubmit={list => {
            this.props.startEditList(this.props.list.id, list)
            this.props.history.push("/");
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userProps: state.userProps
});

const mapDispatchToProps = dispatch => ({
  startEditList: (id, list) => dispatch(startEditList(id, list)),
});

export default compose(connect( mapStateToProps, mapDispatchToProps ))(EditListView);
