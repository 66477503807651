import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Grid, Col } from "react-bootstrap";
import firebase from "firebase/compat/app";
import Layout from "../layout/Layout";
import _ from "lodash";
import List from "./List";
import LinkCard from "./LinkCard";
import ProfileSidebar from "./ProfileSidebar";
import no_list from "../../assets/images/no_list_profile@2x.png";
import HeaderTags from "../layout/HeaderTags";
import img_context_app_store from "../../assets/images/app_store.png";
import img_context_google_play from "../../assets/images/google_play.png";
class PublicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: [],
      posts: [],
    };
  }

  componentDidMount() {
    const username = this.props.match.params.uid;
    this.mounted = true;
    console.log(username, "called");

    if (this.mounted) {
      this.setState({ isLoading: false });

      let userRef = firebase.firestore().collection("usersDB");

      userRef
        .where("displayName", "==", username)
        .get()
        .then((querySnapshot) => {
          const profileData = [];

          querySnapshot.forEach(function(doc) {
            profileData.push(doc.data());
          });

          console.log(profileData, "profileData");
          this.setState({ profileData: profileData[0] });
          this.getProfilePosts(profileData[0].uid);
        })
        .catch((e) => console.log(e));
      this.setState({ isLoading: false });
    }
  }

  getProfilePosts = (uid) => {
    this.setState({ isFetching: false });
    firebase
      .firestore()
      .collection("usersDB")
      .doc(`${uid}`)
      .collection("posts")
      .get()
      .then((docSnapshot) => {
        let postsArr = [];
        docSnapshot.forEach((doc) => {
          postsArr.push(doc.data());
        });
        this.setState({ posts: postsArr });
      });
    this.setState({ isFetching: false });
  };

  render() {
    const { links, displayName } = this.state.profileData;
    const linksArr = _.toArray(links);
    const listsArr = this.state.posts;
    const listsCount = listsArr.length;
    const pageTitle = "Tierly | " + displayName;
    return (
      <Layout>
        <HeaderTags
          title={pageTitle}
          description="Beginner friendly page for learning React Helmet."
          name="Tierly"
          type="article"
        />
        <Grid>
          <div>
            <Col md={3} sm={12} xs={12} style={{ display: "block" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ProfileSidebar
                  profileData={this.state.profileData}
                  listsCount={listsCount}
                />
              </div>
              <div
                className="links-container-flex"
                style={{ display: linksArr.length === 0 ? "none" : "flex" }}
              >
                {linksArr.length === 0 ? (
                  <div />
                ) : (
                  linksArr.map((link) => {
                    return <LinkCard key={link.id} {...link} />;
                  })
                )}
              </div>
              <Col className="no-margin" md={12} xsHidden smHidden>
                <div style={{ marginTop: 12, paddingRight: 4, paddingLeft: 4 }}>
                  <p>Download now for</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 4,
                    }}
                  >
                    <div>
                      <a
                        href="https://apps.apple.com/us/app/tierly/id1513435561"
                        target="new"
                      >
                        <img
                          src={img_context_app_store}
                          className="img-responsive"
                          alt="logo"
                          draggable="false"
                        />
                      </a>
                    </div>
                    <div style={{ marginLeft: 8 }}>
                      <a
                        href="https://play.google.com/store/apps/details?id=dev.varsoft.Tierly&hl=en_US&gl=US"
                        target="blank"
                      >
                        <img
                          src={img_context_google_play}
                          className="img-responsive"
                          alt="logo"
                          draggable="false"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>

            <Col md={6} sm={12} xs={12}>
              {listsArr.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: 100,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={no_list}
                      alt="no  list"
                      style={{ width: "50%" }}
                    />
                    <p>
                      <strong>No lists yet...</strong>
                    </p>
                  </div>
                </div>
              ) : (
                _.reverse(listsArr).map((list) => {
                  return (
                    <List key={list.id} {...list} listsCount={listsCount} />
                  );
                })
              )}
            </Col>
            <Col md={3} smHidden xsHidden>
              {/* <h6>Trending</h6> */}
            </Col>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.userState.users,
  userProps: state.userProps,
});

export default compose(connect(mapStateToProps, undefined))(PublicProfile);
