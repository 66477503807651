import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUserFollowing } from "../../actions/following";
import { Grid, Col } from "react-bootstrap";
import _ from "lodash";
import firebase from 'firebase/compat/app';
import Friend from "./Friend";
import Layout from "../layout/Layout";
import ProfileSidebar from "../publicProfile/ProfileSidebar";

class FollowersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 100,
      isLoading: true,
      isEmpty: false,
      isFinished: false,
      followersData: [],
      profileData: {}
    };
  }

  componentDidMount() {
    const uid  = this.props.location.state.uid;    
    firebase.database().ref(`/follower-data/${uid}`).once("value").then(snap => {
        this.setState({
          followersData: _.reverse(_.toArray(snap.val()))
        });
      }).then(() => {
        firebase.database().ref(`/users/${uid}`).once("value").then(snap => {
          this.setState({
            profileData: snap.val()
          });
      }) 
    })
}

  render() {
    return (
      <Layout>
        <Grid>
        <div>
          <Col md={3} sm={12} xs={12}>
          <ProfileSidebar profileData={this.state.profileData}/>
          </Col>
          <Col md={6} sm={12} xs={12}>
            {this.state.followersData.length === 0 ? (
              <p>
                No followers yet...{" "}
                <span role="img" aria-label="sad emoji">
                  😒
                </span>
              </p>
            ) : (
              this.state.followersData.map(friend => {
                return <Friend key={friend.id} {...friend} />;
              })
            )}
            </Col>
          </div>
        </Grid>
      </Layout>
    );
  }
}

export default (FollowersList = connect(
  state => ({
    followers: state.followers
  }),
  { fetchUserFollowing }
)(FollowersList));
