import React from "react";

const Footer = () => {
  return (
    <div className="center">
      <div className="footer">
        <ul className="footer-ul">
          <li>
            <a href="https://tierlyapp.com/terms.html" target="blank">
              Terms and Privacy
            </a>
          </li>
          <li>
            <a href="https://tierlyapp.com/support.html" target="blank">
              Support
            </a>
          </li>
          <li>
            <a href="https://tierlyapp.com" target="blank">
              App
            </a>
          </li>
          <li>
            <a>© 2024 Tierly. All rights reserved.</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
