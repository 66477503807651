import firebase from 'firebase/compat/app';

export const FETCH_USER_LIKES = "FETCH_USER_LIKES";
export const FETCH_PUBLIC_PROFILE_LIKES = "FETCH_PUBLIC_PROFILE_LIKES";

export function startSetLikes() {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    return firebase.database().ref(`liked/${user.uid}`).once("value").then(snapshot => {
        
        const likes = Object.keys(snapshot.val());
        
        dispatch({
          type: FETCH_USER_LIKES,
          likes
        })

      })
      .catch(err => {});
    }  
};


export function fetchPublicProfileLikes(uid, id) {
  return dispatch => {

    firebase.database().ref(`likes/${uid}/${id}`).on("value", snapshot => {
        
        const likes = snapshot.val()

        console.log(likes, "ACTIONS LIKES")

        dispatch({
          type: FETCH_PUBLIC_PROFILE_LIKES,
          likes
        });

      })
      
  };
}




// export function fetchUserFollowers() {
//   return dispatch => {
//     const userID = firebase.auth().currentUser.uid;

//     firebase.database().ref(`likes/${userID}/`).once("value")
//       .then(snapshot => {
//         const likes = [];

//         snapshot.forEach(childSnapshot => {
//           let followerId = childSnapshot.val();

//           firebase.database().ref(`users/${followerId}/`).once("value", snapshot => {
//               snapshot.forEach(childSnapshot => {
//                 likes.push({
//                   ...snapshot.val()
//                 });
//                 //console.log(likes, "Follower log from action");
//                 return Promise.all(likes);
//               });

//               dispatch({
//                 type: FETCH_USER_LIKES,
//                 likes
//               });
//             });
//         });
//       })
//       .catch(err => {
//         //console.log(err);
//       });
//   };
// }
