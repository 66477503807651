import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import LikeButton from  "../timeline/LikeButton"
import "../../styles/App.css";

class ListCard extends Component {
  render(){
    const { title, createdAt, photoURL, displayName, description, id, likes, verified } = this.props
    return(
      <Link to={`/${displayName}/lists/${id}`}>
      <div className="list-card-style">
          <div className="list-card">
            <div className="list-profile-avatar-wrapper">
              <img src={photoURL} className="list-profile-avatar" alt="profile img"/>
            </div>
            <div className="list-detail-wrapper">
              <Link to={`/${displayName}/`}><p className="list-user-name">@{displayName} {verified ? <div className="verified"></div> : <div></div> }</p></Link>
              <p className="list-title">{title}</p>
              <p className="list-description">{description}</p>
              <p className="timestamp">{moment(createdAt).fromNow()}</p>
            </div>
            <div className="likes-container">
              <div style={{ flexDirection: "column" }}>
                <div>
                  <LikeButton likes={likes} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default ListCard