import React, { Component } from "react";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Button, Col } from "react-bootstrap";
import firebase from "firebase/compat/app";
import _ from "lodash";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  width: "100%",
  // change background colour if dragging
  background: isDragging ? "#EEEEEE" : "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#FFFFFF" : "f5f5f7",
  width: "100%",
});

class NewListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: firebase.auth().currentUser.displayName,
      photoURL: firebase.auth().currentUser.photoURL,
      uid: firebase.auth().currentUser.uid,
      title: props.list ? props.list.title : "",
      id: firebase
        .database()
        .ref()
        .push().key,
      slug: props.list ? props.list.slug : "",
      items: [],
      img_url: "",
      likes: 0,
      createdAt: props.list ? moment(props.list.createdAt) : moment(),
      imagePath: null,
      picture: null,
      error: "",
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }
  state = {
    title: "",
    createdAt: moment(),
    items: [],
    imagePath: "",
    img_url: "",
    slug: "",
    error: "",
  };

  slug = (title) => {
    if (title) {
      return title
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .join("-")
        .toLowerCase();
    } else {
      return title;
    }
  };

  onTitleChange = (e) => {
    const title = e.target.value;
    if (title != null || title !== "") {
      this.slug(title);
    }
    this.setState(() => ({ title, slug: this.slug(title) }));
  };

  handleAddItem(link_title, link_url) {
    if (!link_title) {
      return "Enter valid value to add item";
    } else if (this.state.items.indexOf(link_title) > -1) {
      return "This item already exists";
    }

    let item = {
      link_title,
      link_url,
      id: _.toString(this.state.items.length + 1),
    };

    this.setState((prevState) => {
      return {
        items: prevState.items.concat(item),
      };
    });
  }

  removeItem(item) {
    const updatedItemCount = this.state.items.filter((items) => {
      return items !== item;
    });
    this.setState({
      items: [...updatedItemCount],
    });
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.title) {
      this.setState(() => ({ error: "At least write a title" }));
    } else {
      this.setState(() => ({ error: "" }));

      if (this.state.picture === null || this.state.picture === "") {
        this.props.onSubmit({
          displayName: this.state.displayName,
          title: this.state.title,
          slug: this.state.slug,
          photoURL: this.state.photoURL,
          img_url: "",
          likes: this.state.likes,
          uid: this.state.uid,
          items: this.state.items,
          createdAt: this.state.createdAt.valueOf(),
        });
      } else {
        e.preventDefault();
        const imageName = `${this.state.id}.jpg`;

        const { uid } = firebase.auth().currentUser;
        const folderName = `${uid}`;

        var metadata = {
          contentType: "image/jpeg",
        };
        const imageRef = firebase
          .storage()
          .ref(`content/${folderName}/${imageName}`);

        imageRef
          .put(this.state.picture, metadata)
          .then(() => {
            return imageRef.getDownloadURL();
          })
          .then((url) => {
            this.props.onSubmit({
              displayName: this.state.displayName,
              title: this.state.title,
              slug: this.state.slug,
              photoURL: this.state.photoURL,
              img_url: url,
              likes: this.state.likes,
              uid: this.state.uid,
              items: this.state.items,
              createdAt: this.state.createdAt.valueOf(),
            });
          })
          .then(() => alert("Image uploaded"))
          .catch((error) => console.log(error));
      }
    }
  };

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  }

  displayPicture(event) {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        picture: file,
        img_url: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const { input } = this.props;
    const { img_url } = this.state;

    console.log(this.state.slug, "this.state.slug");
    const post_image = img_url ? (
      <div className="account-img-wrapper">
        <img
          src={img_url}
          className="account-user-img"
          alt="user avatar"
          draggable={false}
        />
      </div>
    ) : (
      <div
        style={{ display: "flex", backgroundColor: "#FFF", borderRadius: 12 }}
      >
        <div style={{ width: 50, height: 50, paddingTop: 12, paddingLeft: 12 }}>
          <div>
            <label htmlFor="files">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <i
                    className="fas fa-image"
                    style={{ fontSize: 24, color: "#adadaf" }}
                  />
                </div>
              </div>
            </label>
            <input
              id="files"
              onChange={(event) => {
                this.displayPicture(event);
              }}
              {...input}
              style={{ visibility: "hidden" }}
              type="file"
            />
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {this.state.error && <p>{this.state.error}</p>}
        <form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>{post_image}</div>
            </div>

            <div style={{ marginLeft: 16, width: "100%" }}>
              <input
                style={{
                  height: 45,
                  width: "100%",
                  borderRadius: 12,
                  paddingLeft: 8,
                }}
                type="text"
                placeholder="List title"
                autoFocus
                value={this.state.title}
                onChange={this.onTitleChange}
              />
            </div>
          </div>

          <div>
            {this.state.items.length === 0 ? (
              <p className="no-items-state-style">
                Nothing in your list yet...{" "}
                <span role="img" aria-label="sad emoji">
                  😒
                </span>
              </p>
            ) : (
              <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className="item-card"
                              >
                                {item.link_url ? (
                                  <div className="list-profile-link-wrapper">
                                    <div className="item-link-container">
                                      <div className="item-link">
                                        <i
                                          className="fa fa-link item-link-icon-style"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div />
                                )}

                                <div className="item-detail-wrapper">
                                  <div>
                                    <p className="item-content">
                                      {item.link_title}
                                    </p>
                                    <p className="item-link-url item-link-wrapper">
                                      {item.link_url}
                                    </p>
                                  </div>
                                </div>
                                <div className="item-link-container">
                                  <div className="delete-link-icon">
                                    <div
                                      index={index}
                                      onClick={(e) => this.removeItem(item)}
                                    >
                                      <i className="fas fa-trash-alt delete-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </div>

          <AddItemForm items={this.props.items} onSubmit={this.handleAddItem} />
        </form>
        <Button onClick={this.onSubmit}>Share tierly</Button>
      </div>
    );
  }
}

// const PreviewPicture = props => {
//   const { img_url } = props;
//   return (
//     <div className="account-img-wrapper">
//       <img src={img_url}  className="account-user-img" alt="user avatar" draggable={false} />
//     </div>
//   )}

class AddItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items: this.props.items ? this.props.items : [],
      link_title: props.item ? props.item.link_title : "",
      link: props.item ? props.item.link : "",
      error: undefined,
    };
  }

  state = {
    link_title: "",
    link_url: "",
    error: "",
  };

  onContentChange = (e) => {
    const link_title = e.target.value;
    this.setState(() => ({ link_title }));
  };

  onLinkChange = (e) => {
    const link_url = e.target.value;
    this.setState(() => ({ link_url }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.link_title) {
      this.setState(() => ({ error: "Please provide item" }));
    } else {
      this.setState(() => ({ error: "", link_title: "", link_url: "" }));
      this.props.onSubmit(this.state.link_title, this.state.link_url);
    }
  };

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className="list-card-style">
        {this.state.error && <p>{this.state.error}</p>}
        <Form
          autoComplete="off"
          onKeyPress={this.onKeyPress}
          onSubmit={this.onSubmit}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 16 }}>
              <i className="fas fa-pencil-alt" />
            </div>
            <div className="add-item-input">
              <input
                type="text"
                placeholder="Link title"
                value={this.state.link_title}
                onChange={this.onContentChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 16 }}>
              <i className="fa fa-link" aria-hidden="true" />
            </div>
            <div className="add-item-input">
              <input
                type="text"
                placeholder="Paste link here (Where does this link to?)"
                value={this.state.link_url}
                onChange={this.onLinkChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </div>
          </div>

          <div onClick={this.onSubmit} className="add-item-btn-container">
            <div className="add-item-btn">
              <strong>+ Add link</strong>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default NewListForm;

const styles = {
  privateInfoContainer: {
    paddingTop: 20,
    // borderColor: '#CCCCCC',
    // borderTopWidth: 2,
  },
  privateInfoText: {
    color: "#9E9E9E",
    fontWeight: "bold",
  },
  userAccountTextInput: {
    flexDirection: "row",
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: "#FFFFFF",
    height: 40,
    flex: 1,
    borderColor: "#CCCCCC",
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    paddingTop: 20,
    fontWeight: "bold",
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: "#304FFE",
    padding: 10,
    margin: 10,
    backgroundColor: "#304FFE",
    height: 40,
  },
  submitButtonText: {
    color: "#ffffff",
    textAlign: "center",
  },
};
