import React, { Component, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import withAuthorization from "../session/withAuthorization";
import { Grid, Col } from "react-bootstrap";
import Layout from "../layout/Layout";
import List from "./List";
import TimelineSidebar from "../publicProfile/ProfileSidebar";
import _ from "lodash";
import LinkCard from "../publicProfile/LinkCard";
import SignIn from "../signIn";
import configureStore from "../../store/configureStore";
import { fetchTimelineLists } from "../../actions/lists";
import { startUserProps } from "../../actions/userProps";
import { login } from "../../actions/auth";
import UserCard from "../explore/ProfileSidebar";
import img_context_app_store from "../../assets/images/app_store.png";
import img_context_google_play from "../../assets/images/google_play.png";
const store = configureStore();

const Timeline = (props) => {
  const [profileData, setProfileData] = useState(props.userProps);
  const [lists, setLists] = useState(props.lists ? props.lists : []);
  const [isLoading, setIsLoading] = useState(true);

  const getUserPropsTimeline = useCallback(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      store.dispatch(login(user));
      firebase
        .firestore()
        .collection("usersDB")
        .doc(`${user.uid}`)
        .get()
        .then((snapshot) => {
          const profileData = snapshot.data();
          setProfileData(profileData);
        });
    }
  }, []);

  const getUserPropsTimelinePosts = useCallback(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      store.dispatch(login(user));
      firebase
        .firestore()
        .collection("timelinesDB")
        .doc("timelines")
        .collection(`${user.uid}`)
        .orderBy("createdAt", "desc")
        .limit(5)
        .get()
        .then((docSnapshot) => {
          const lists = [];
          docSnapshot.forEach((doc) => {
            lists.push(doc.data());
          });

          setLists(lists);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  useEffect(() => {
    getUserPropsTimeline();
    getUserPropsTimelinePosts();
  }, [props.authUser]);

  const { links } = profileData;
  const linksArr = _.toArray(links);
  const { featured_users } = props.discover;
  return (
    <div>
      {props.authUser === null ? (
        <>
          <SignIn />
        </>
      ) : (
        <Layout>
          <Grid>
            <div>
              <Col md={3} sm={12} xs={12} style={{ display: "block" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TimelineSidebar profileData={profileData} />
                  <Col className="no-margin" md={12} xsHidden smHidden>
                    <div
                      style={{ marginTop: 12, paddingRight: 4, paddingLeft: 4 }}
                    >
                      <p>Download now for</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 4,
                        }}
                      >
                        <div>
                          <a
                            href="https://apps.apple.com/us/app/tierly/id1513435561"
                            target="new"
                          >
                            <img
                              src={img_context_app_store}
                              className="img-responsive"
                              alt="logo"
                              draggable="false"
                            />
                          </a>
                        </div>
                        <div style={{ marginLeft: 8 }}>
                          <a
                            href="https://play.google.com/store/apps/details?id=dev.varsoft.Tierly&hl=en_US&gl=US"
                            target="blank"
                          >
                            <img
                              src={img_context_google_play}
                              className="img-responsive"
                              alt="logo"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
                <div className="links-container-flex">
                  {linksArr.length === 0 ? (
                    <div />
                  ) : (
                    linksArr.map((link) => {
                      return <LinkCard key={link.id} {...link} />;
                    })
                  )}
                </div>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <h4 style={{ lineHeight: 2 }}>#following</h4>
                {lists.length === 0 ? (
                  <p>
                    No posts yet...
                    <span role="img" aria-label="sad emoji">
                      😒
                    </span>
                  </p>
                ) : (
                  lists.map((list) => {
                    return <List key={list.id} {...list} />;
                  })
                )}
                <h4 style={{ lineHeight: 2 }}>#dicover</h4>
                {props.explore.length === 0 ? (
                  <p>
                    No posts yet...
                    <span role="img" aria-label="sad emoji">
                      😒
                    </span>
                  </p>
                ) : (
                  props.explore.map((list) => {
                    return <List key={list.id} {...list} />;
                  })
                )}
              </Col>
              <Col md={3} sm={6} xsHidden>
                <h4 style={{ lineHeight: 2 }}>#featured</h4>
                {_.toArray(featured_users).length === 0 ? (
                  <p>
                    No posts yet...
                    <span role="img" aria-label="sad emoji">
                      😒
                    </span>
                  </p>
                ) : (
                  _.toArray(featured_users).map((list) => {
                    return <UserCard key={list.id} {...list} />;
                  })
                )}
                {/* <h6>Likes</h6> */}
                {/* <LikedLists {...userProps}/> */}
                {/* <h6>Trending</h6> */}
              </Col>
            </div>
          </Grid>
        </Layout>
      )}
    </div>
  );
};

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  userProps: state.userProps,
  authUser: state.sessionState.authUser,
  lists: state.lists,
  discover: state.discover,
  explore: state.explore,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUser: (userProps) => dispatch({ type: "USER_SET", userProps }),
  startUserProps: (uid) => dispatch(startUserProps(uid)),
});

// export default compose(withAuthorization(authCondition), connect(mapStateToProps, mapDispatchToProps))(Timeline);
export default compose(connect(mapStateToProps, mapDispatchToProps))(Timeline);
