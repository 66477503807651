// Lists Reducer
import { SET_EXPLORE_LISTS } from '../actions/explore'


const exploreListsDefaultState = [];

export default (state = exploreListsDefaultState, action) => {
  switch (action.type) {
        
    case SET_EXPLORE_LISTS:
      return action.exploreLists
    
    default:
      return state;
  }
};
