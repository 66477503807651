// import firebase from 'firebase/app';
// import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const prodConfig = {
  apiKey: "AIzaSyDAdgmXZrb2Qh36WyeUC2YEoE3jZ9WvlbY",
  authDomain: "tierly-712dc.firebaseapp.com",
  databaseURL: "https://tierly-712dc.firebaseio.com",
  projectId: "tierly-712dc",
  storageBucket: "tierly-712dc.appspot.com",
  messagingSenderId: "374110035821"
};

const devConfig = {
  apiKey: "AIzaSyDAdgmXZrb2Qh36WyeUC2YEoE3jZ9WvlbY",
  authDomain: "tierly-712dc.firebaseapp.com",
  databaseURL: "https://tierly-712dc.firebaseio.com",
  // databaseURL: "https://tierly-dev.firebaseio.com",
  projectId: "tierly-712dc",
  storageBucket: "tierly-712dc.appspot.com",
  messagingSenderId: "374110035821"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  // const app = initializeApp(config);
  firebase.initializeApp(config);
}

// const app = initializeApp(firebaseConfig);
// const fireStoreDB = getFirestore(app)
// const app = initializeApp(config);
// const dbstore = getFirestore(config);
const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();


export {
  db,
  auth,
  storage,
  firebase,
  googleAuthProvider,
  database as default 
};



// import * as firebase from 'firebase';

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
// };

// firebase.initializeApp(config);

// const database = firebase.database();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// export { firebase, googleAuthProvider, database as default };
