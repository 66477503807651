import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Col,
  FormGroup,
  FormControl,
  Button,
  Modal,
} from "react-bootstrap";
import { PasswordForgetLink } from "../passwordForget";
import { auth } from "../../firebase";
import * as routes from "../../constants/routes";
import img_context_app_store from "../../assets/images/app_store.png";
import img_context_google_play from "../../assets/images/google_play.png";
import logo from "../../assets/images/logo@2x.png";
import Footer from "../layout/Footer";
import SignUpPage from "../signUp/";
import configureStore from "../../store/configureStore";
import { startUserProps } from "../../actions/userProps";
import firebase from "firebase/compat/app";
const store = configureStore();
class SignInPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.state = {
      show: false,
    };
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleHide() {
    this.setState({ show: false });
  }

  render() {
    const { history } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <Grid style={{ height: "100%", marginTop: 100, marginBottom: 100 }}>
            <Grid>
              <Col md={6} sm={6}>
                <div className="app-header">
                  <a href="https://tier.ly">
                    <img
                      src={logo}
                      className="sign-in-logo"
                      alt="logo"
                      draggable="false"
                    />
                  </a>
                  <div style={{ maxWidth: 400 }}>
                    <h2 className="header_p">
                      Where the world's taste is organized.
                    </h2>
                    <h2 className="header_p">
                      {" "}
                      <strong>Curate</strong>{" "}
                      <span
                        role="img"
                        alt="rock on!"
                        aria-label="rock on emoji"
                      >
                        🤘
                      </span>{" "}
                      <strong>Share</strong>{" "}
                      <span
                        role="img"
                        alt="rock on!"
                        aria-label="rock on emoji"
                      >
                        🤘
                      </span>{" "}
                      <strong>Inspire</strong>
                    </h2>
                    <p className="header_p">
                      Introduce what you love to the world by sharing curated{" "}
                      <strong>links</strong> of your favorites.
                    </p>
                  </div>
                  <hr></hr>
                  <Button
                    onClick={this.handleShow}
                    className="btn-blank sign-up-tierly-btn"
                    bsSize="large"
                    block
                    style={{ maxWidth: 400, marginTop: "10px" }}
                  >
                    Create account
                  </Button>
                </div>
              </Col>
              <Col md={6} sm={6}>
                <div className="app-header">
                  <div
                    className="homefeed-list-card-style"
                    style={{ maginBottom: "16px !important" }}
                  >
                    <div className="list-card">
                      <div style={{ margin: 24 }}>
                        <h2 className="header_p">Login</h2>
                        <SignInForm history={history} />
                        <PasswordForgetLink />
                      </div>
                    </div>
                  </div>
                  <Col className="no-margin" md={12} sm={12}>
                    <Modal
                      {...this.props}
                      show={this.state.show}
                      onHide={this.handleHide}
                      dialogClassName="custom-modal"
                    >
                      <Modal.Body closeButton>
                        <SignUpPage />
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col className="no-margin" md={12} sm={12}>
                    <hr></hr>
                    <p>Download now for</p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        <a
                          href="https://apps.apple.com/us/app/tierly/id1513435561"
                          target="blank"
                        >
                          <img
                            src={img_context_app_store}
                            className="sign-in-logo"
                            alt="logo"
                            draggable="false"
                          />
                        </a>
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        <a
                          href="https://play.google.com/store/apps/details?id=dev.varsoft.Tierly&hl=en_US&gl=US"
                          target="blank"
                        >
                          <img
                            src={img_context_google_play}
                            className="sign-in-logo"
                            alt="logo"
                            draggable="false"
                          />
                        </a>
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
            </Grid>
          </Grid>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then((res) => {
        this.setState(() => ({ ...INITIAL_STATE }));
        const user = firebase.auth().currentUser;
        console.log(user, "CALLED FORM LOGIN");
        store.dispatch(startUserProps(user));
        history.push(routes.TIMELINE);
      })
      .catch((error) => {
        this.setState(updateByPropertyName("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <div>
        <FormGroup>
          <form onSubmit={this.onSubmit}>
            <Col className="input-landing-margin" md={12} sm={12}>
              <FormGroup bsSize="large">
                <FormControl
                  style={{ backgroundColor: "#E9E9EE" }}
                  value={email}
                  onChange={(event) =>
                    this.setState(
                      updateByPropertyName("email", event.target.value)
                    )
                  }
                  type="text"
                  autoComplete="email"
                  placeholder="Email"
                />
              </FormGroup>
            </Col>
            <Col className="input-landing-margin" md={12} sm={12}>
              <FormGroup bsSize="large">
                <FormControl
                  style={{ backgroundColor: "#E9E9EE" }}
                  value={password}
                  autoComplete="current-password"
                  onChange={(event) =>
                    this.setState(
                      updateByPropertyName("password", event.target.value)
                    )
                  }
                  type="password"
                  placeholder="Password"
                />
              </FormGroup>
            </Col>
            <Col className="input-landing-margin" md={12} sm={12}>
              <Button
                bsSize="large"
                bsStyle="primary"
                block
                style={{
                  maxWidth: 400,
                  marginBottom: "10px",
                  backgroundColor: "#E9E9EE",
                }}
                className="btn-blank login-tierly-btn"
                disabled={isInvalid}
                type="submit"
              >
                Sign In
              </Button>
            </Col>
            {error && <p>{error.message}</p>}
          </form>
        </FormGroup>
      </div>
    );
  }
}

export default withRouter(SignInPage);

export { SignInForm };
