import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "../../styles/App.css";
import _ from "lodash";
class List extends Component {  
  render(){
    const { title, createdAt, photoURL, items, displayName, id, slug, img_url } = this.props
    const itemsArray = _.toArray(items);
    return(
      <Link to={`/${displayName}/${slug}`}>
      <div className="homefeed-list-card-style" style={{maginBottom: '16px !important'}}>
          <div className="list-card">

            <div className="list-profile-avatar-wrapper">
              <img src={photoURL} className="list-profile-avatar" alt="profile img" />
            </div>

            <div className="list-detail-wrapper">
            <div>
              <Link to={{ pathname: `/${displayName}/` }}>
                <p className="list-user-name" style={{color: '#666'}}>{displayName}</p>
              </Link>
              <div style={{marginTop: 4, flex: 1}}>
                <p className="list-title">{title}</p>
              </div>
              <div style={{display: 'flex', flex:1, flexDirection: 'row', marginTop: 4}}>
                <div className="impressions-link-container">
                  <div style={{ marginRight: 4, marginLeft: 8}}>
                    <i class="fa fa-link" style={{ color: '#666', fontSize: 10, }} />
                  </div>
                  <div style={{ marginRight: 8, marginTop: 2}}>
                    <p style={{fontSize: 10, color: '#666', fontWeight: 'bold'}}>{itemsArray.length}</p>
                  </div>
                </div>
                <div className="impressions-link-container" style={{ marginLeft: 4}}>
                  <div style={{ marginRight: 4, padding:4,}}>
                    <p style={{fontSize: 10, color: '#666', fontWeight: 'bold'}}>{moment(createdAt).fromNow()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>          
          
          </div>

          {img_url? (
              <div className="homefeed-img-container">
                <div className="homefeed-img-box">
                  <img src={img_url} alt="post" style={{ width: '100%', height: '100%' }} />
                </div>                
            </div>
            ) : ( <div/> )}

        </div>
      </Link>
    )
  }
}

export default List;

