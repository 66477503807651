import React, { Component, useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import { connect } from "react-redux";
import _ from "lodash";
import { getComments, startAddComment } from "../../actions/comments";
import Comment from "./Comment";

const CommentsList = (props) =>  {
  const [comments, setComments] = useState();
  useEffect(() => {
    console.log(props.id, 'comments ID ')
    // const { id } = this.props.id;
    // firebase.database().ref(`comments/${id}/comment/`).orderByChild('createdAt').once('value').then(snapshot => {
    //   const comments = snapshot.val()
    //   console.log(comments, 'comments COMEMNTS')
    //   this.setState({ comments })
    // })
  }, []);
  
  const commentsArray = _.toArray(comments);
  return (
    <div>
      {commentsArray.map((comments, key) => {
            return <Comment key={key} {...comments} />;
          })
        } 
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  comments: state.comments,
});

const mapDispatchToProps = (dispatch, props) => ({
  getComments,
  startAddComment: (id, commentBody) => dispatch(startAddComment(id, commentBody))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsList);
