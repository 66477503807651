import React, { Component } from 'react';
import { connect } from "react-redux";
import Navigation from './index.js';
import TabBar from './TabBar.js';
import { Button } from 'react-bootstrap';
// Style sheet
import { HelmetProvider } from 'react-helmet-async';
import '../../styles/App.css';
import Footer from './Footer'

class Layout extends Component {
  render() {
    return (
      <HelmetProvider>
      <div>
        <Navigation/>
        <div className="app-wrapper">
          {this.props.children}
          <Footer/>
          <div style={{height: 100}}></div>
        </div>
        {this.props.authUser === null ? (
          <div className="footer-banner">
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <div className='footer-banner-text' style={{width: '40%'}}>
                <strong><p style={{fontSize: 22, fontWeight: 'bold'}}>Start curating links and share them anywhere.</p></strong>
                <p> Sign up for free account now</p>
              </div>

              <div style={{marginLeft: 16, marginRight: 16}}>
                <div className="footer-banner-btn">
                  <a href="https://tier.ly/" target="_blank" style={{color: '#FFF'}}>
                    Join
                  </a>
                </div>
              </div>
              
              
            </div>
          </div>
        ) : (<></>) }
        <TabBar/>
      </div>
      </HelmetProvider>
    );
  }
}


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps
});

export default connect(mapStateToProps, undefined)(Layout);