import { FETCH_USER_FOLLOWERS } from '../actions/followers'
const defaultUserFollowersState = [];

export default (state = defaultUserFollowersState, action) => {
  switch (action.type) {
    case FETCH_USER_FOLLOWERS:
      return action.followers;
    default:
      return state;
  }
};
