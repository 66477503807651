import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import _, { set } from "lodash";
import FollowButtonFake from "../followButton/FollowButtonFake";
import { Modal, Col, Button } from "react-bootstrap";
import "../../styles/App.css";
import firebase from "firebase/compat/app";
import Account from "../account";
import img_context_app_store from "../../assets/images/app_store.png";
import img_context_google_play from "../../assets/images/google_play.png";

const NotSingedInFollowBtn = () => (
  <div>
    <Link to={routes.SIGN_IN}>
      <div className="follow-btn">
        <div>
          <p className="follow-button-text">Subscribe</p>
        </div>
      </div>
    </Link>
  </div>
);

const ProfileSidebarButton = (props) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      {props.userProps.uid === props.uid ? (
        <div onClick={() => setShowModal(!showModal)}>
          <div className="unfollow-btn">
            <div>
              <p className="unfollow-button-text">Edit profile</p>
            </div>
          </div>
        </div>
      ) : (
        <FollowButtonFake profileData={props.profileData} />
      )}
      <div>
        <Modal
          {...props}
          backdrop="static"
          keyboard={false}
          show={showModal}
          onHide={() => setShowModal(!showModal)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
              Edit profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ width: "100%" }}>
            <Account />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

class ProfileSidebar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      user: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleHide() {
    this.setState({ show: false });
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: true });
      }
    });
  }
  render() {
    const {
      photoURL,
      uid,
      displayName,
      bio,
      posts,
      followers,
      following,
      verified,
    } = this.props.profileData;

    return (
      <Col
        md={3}
        sm={12}
        xs={12}
        className="profile-user-card-wrapper no-margin"
      >
        <Col
          lgHidden
          mdHidden
          sm={12}
          xs={12}
          className=""
          style={{ paddingTop: 20 }}
        >
          <div className="sidebar-user-details-wrapper">
            <div>
              <Link to={{ pathname: `/${displayName}/` }}>
                <img
                  src={photoURL}
                  className="sidebar-user-img"
                  alt="profile img"
                />
              </Link>
            </div>
            <div style={{ width: "100%", marginLeft: 12 }}>
              <a href={`/${displayName}`}>
                <strong>
                  <p className="sidebar-user-name">
                    {displayName}{" "}
                    {verified ? <span className="verified" /> : <span />}{" "}
                  </p>
                </strong>
              </a>
              <p className="sidebar-user-bio">{bio}</p>

              {this.state.user ? (
                <div>
                  {this.props.userProps.uid === this.props.uid ? (
                    <div
                      className="unfollow-btn"
                      onClick={() => this.handleShow()}
                    >
                      <div>
                        <p className="unfollow-button-text">Edit profile</p>
                      </div>
                    </div>
                  ) : (
                    <FollowButtonFake profileData={this.props.profileData} />
                  )}
                </div>
              ) : (
                <div></div>
              )}
              {!this.state.user ? <NotSingedInFollowBtn /> : <div></div>}

              <div className="sidebar-user-stats">
                <div>
                  <a onClick={() => this.handleShow()}>
                    <strong>Posts</strong> <p>{posts}</p>{" "}
                  </a>
                </div>
                <div>
                  <a onClick={() => this.handleShow()}>
                    <strong>Subscribers</strong> <p>{followers}</p>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={12} smHidden xsHidden>
          <Col md={12} sm={12} className="no-margin">
            <div className="sidebar-img-wrapper">
              <img
                src={photoURL}
                className="sidebar-user-img"
                alt="user avatar"
                draggable={false}
              />
            </div>

            <div className="sidebar-card">
              <div className="sidebar-user-details-wrapper">
                <div>
                  <a href={`/${displayName}`}>
                    <strong>
                      <p className="sidebar-user-name">
                        {displayName}{" "}
                        {verified ? <span className="verified" /> : <span />}{" "}
                      </p>
                    </strong>
                  </a>
                </div>
                <div>
                  <p className="sidebar-user-bio">{bio}</p>
                </div>
              </div>
            </div>
          </Col>

          <Col md={12} sm={12} className="no-margin">
            {this.state.user ? (
              <ProfileSidebarButton
                uid={uid}
                userProps={this.props.userProps}
                profileData={this.props.profileData}
              />
            ) : (
              <div></div>
            )}
            {!this.state.user ? <NotSingedInFollowBtn /> : <div></div>}

            <div className="sidebar-user-stats">
              <div>
                <a onClick={() => this.handleShow()}>
                  <strong>Posts</strong> <p>{posts}</p>{" "}
                </a>
              </div>
              <div>
                <a onClick={() => this.handleShow()}>
                  <div style={{ fontWeight: "900" }}>Subscribers</div>{" "}
                  <p>{followers}</p>{" "}
                </a>
              </div>
            </div>
          </Col>
        </Col>
        <div>
          <Modal
            {...this.props}
            show={this.state.show}
            onHide={this.handleHide}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-lg">
                Download app
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ width: "100%" }}>
              To access all features download the app from the following places!
              <div style={{ marginTop: 12, paddingRight: 4, paddingLeft: 4 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 4,
                  }}
                >
                  <div>
                    <a
                      href="https://apps.apple.com/us/app/tierly/id1513435561"
                      target="new"
                    >
                      <img
                        src={img_context_app_store}
                        className="img-responsive"
                        alt="logo"
                        draggable="false"
                      />
                    </a>
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=dev.varsoft.Tierly&hl=en_US&gl=US"
                      target="blank"
                    >
                      <img
                        src={img_context_google_play}
                        className="img-responsive"
                        alt="logo"
                        draggable="false"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  userProps: state.userProps,
});

export default connect(mapStateToProps, undefined)(ProfileSidebar);
