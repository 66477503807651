const getLists = (lists, { text }) => {
  return lists.filter(list => {
    const textMatch =
      list.title.toLowerCase().includes(text.toLowerCase()) ||
      list.displayName.toLowerCase().includes(text.toLowerCase());
    return textMatch;
  });
};

export default getLists;
