import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import LikeButton from  "../timeline/LikeButton"
import "../../styles/App.css";

class List extends Component {
  render(){
    const { title, createdAt, photoURL, displayName, slug, likes, img_url, verified } = this.props
    return(
      <Link to={`/${displayName}/p/${slug}`}>
      <div className="list-card-style">
          <div className="list-card">

            <div className="list-profile-avatar-wrapper">
              <img src={photoURL} className="list-profile-avatar" alt="profile img"/>
            </div>
            
            <div className="list-detail-wrapper">
              <div>
              <p className="list-user-name">{displayName} {verified ? <span className="verified"></span> : <span></span> }</p>
              <p className="list-title">{title}</p>
              <p className="timestamp">{moment(createdAt).fromNow()}</p>
            </div>
            </div>
            
            {img_url ? (
              <div className="likes-container">
              <div style={{ flexDirection: "column" }}>
                <div className="img_box" style={{backgroundImage: img_url}}>
                  <img src={img_url} alt="post" style={{ boder: 'none', maxWidth: 80, maxHeight: 80, overflow: "hidden", backgroundSize: "cover", backgroundPosition: "center", borderTopRightRadius: 16, borderBottomRightRadius: 16 }} />
                </div>                
              </div>
            </div>
            ) : ( <div style={{ boder: 'none', maxWidth: 80, minHeight: 80}} /> )}
            
            
          </div>
        </div>
      </Link>
    )
  }
}

export default List