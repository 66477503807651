import database from "../firebase/firebase";

export const FETCH_USER_FOLLOWING = "FETCH_USER_FOLLOWING";

export function fetchUserFollowing(displayName) {
  return dispatch => {
        database.ref(`following/${displayName}/`).on('value', snapshot => {
          let following = Object.keys(snapshot.val())
          console.log(following, "ACTIONS")
          dispatch({
            type: FETCH_USER_FOLLOWING,
            following: snapshot.val()
          })
        })
    
  }
}
